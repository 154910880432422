import { isDeepEqual } from '@material-ui/data-grid';
import { useEffect, useState } from 'react';
import { useGetClientById } from 'src/clients/hooks/useGetClientById';
import { useUpdateClient } from 'src/clients/hooks/useUpdateClient';
import type { Client } from 'src/clients/types';
import { useGlobals } from 'src/globals/hooks';

// Copies current Globals into the client record
export const useAutoUpdateClientGlobals = (id?: string) => {
  const { data: globals } = useGlobals();
  const record = useGetClientById(id);
  const [update] = useUpdateClient(record);

  const [done, setDone] = useState(false);

  useEffect(() => {
    if (done || !id || !globals || !record || !update) return;

    const isUpToDate = isDeepEqual(record.globals, globals);
    if (isUpToDate) return;

    const { use_global_inflation } = record;
    const { inflation_rate } = globals;

    const updatedClient: Client = {
      ...record,
      ...(use_global_inflation && { inflation_rate }),
      globals,
    };

    update({ payload: { data: updatedClient } });
    setDone(true);
  }, [update, id, record, globals, done]);
};
