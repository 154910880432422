import { useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import {
  useDispatchSnapshot,
  useLoadedSnapshotId,
} from 'src/clients/snapshots/redux';
import type { Client, ClientData } from 'src/clients/types';
export const useIsSnapshotLoaded = () => !!useLoadedSnapshot();

export const useLoadedSnapshot = () => {
  const selectedId = useLoadedSnapshotId();
  const record = useRecordContext() as Client | undefined;

  const snapshot = record?.snapshots.find(({ id }) => id === selectedId);

  return snapshot;
};

export const useLoadedSnapshotData = () => useLoadedSnapshot()?.data;

export const useSnapshotOrRecordContext = () => {
  const snapshot = useLoadedSnapshotData();
  const record: ClientData = useRecordContext();
  return { clientData: snapshot ?? record, isSnapshot: !!snapshot };
};

// Unload snapshot on mount
// Used when opening a client
export const useUnloadSnapshot = () => {
  const dispatch = useDispatchSnapshot();

  useEffect(() => {
    dispatch();
    return () => {
      dispatch();
    };
  }, []);
};
