/**
 * Cumulative sum of an array, using ages as base so it will fit on the transactions timescale
 *
 * @param agesArray [0, ..., n]
 * @param array the values to be summed [3, 5, 4]
 * @returns [3, 8, 12]
 */
export const getCumulativeSumByAgeArray = (
  agesArray: number[],
  array: number[]
): number[] =>
  agesArray.reduce(
    (acc, age) => [...acc, (acc[age - 1] || 0) + (array[age] || 0)],
    [] as number[]
  );
