import { getInitialAge } from 'src/calc/age/getInitialAge';
import type { MainOrPartnerOrBoth } from 'src/calc/transactions/getIncome';
import { getKiwiSaver } from 'src/calc/transactions/getKiwiSaver';
import {
  makeAgeGridData,
  makePartnerAgeGridData,
} from 'src/clients/tables/functions/makeAgeGridData';
import { makeGridColumns } from 'src/clients/tables/functions/makeGridColumns';
import { makeGridRows } from 'src/clients/tables/functions/makeGridRows';
import { makeYearGridData } from 'src/clients/tables/functions/makeYearGridData';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';

export const makeKiwisaverGridData = (
  clientData: ClientData,
  mainOrPartner: MainOrPartnerOrBoth
) => {
  const data = getKiwiSaver(clientData);

  // the calculations iterate on the length of the longest transaction (greatest year_from or year_to)
  const maxLength = data.totals.total.length;

  // shows age array as first column of the grid
  const { ageColumn, yearColumn } = makeMainOrPartnerAgeYearColumns(
    clientData,
    mainOrPartner
  );

  const mainOrPartnerData = mainOrPartner ? data[mainOrPartner] : data.totals;

  // table columns data populated based on client/partner selected
  const salaryColumn: TransactionTableData = {
    id: 'salary',
    title: 'Salary',
    array: mainOrPartnerData.totalSalary,
  };
  const employeeColumn: TransactionTableData = {
    id: 'employee',
    title: 'Employee',
    array: mainOrPartnerData.employee,
  };
  const employerColumn: TransactionTableData = {
    id: 'employer',
    title: 'Employer',
    array: mainOrPartnerData.employer,
  };
  const voluntaryColumn: TransactionTableData = {
    id: 'voluntary',
    title: 'Voluntary',
    array: mainOrPartnerData.voluntary,
  };
  const govtColumn: TransactionTableData = {
    id: 'govt',
    title: 'Govt',
    array: mainOrPartnerData.govt,
  };
  const totalColumn: TransactionTableData = {
    id: 'total',
    title: 'Year Total',
    array: mainOrPartnerData.total,
  };
  const cumulativeTotalColumn: TransactionTableData = {
    id: 'cumTotal',
    title: 'Cumulative Total',
    array: mainOrPartnerData.cumulative,
  };

  // useful for reading the data in other functions
  const allColumnsObject = {
    yearColumn,
    ageColumn,
    salaryColumn,
    employeeColumn,
    employerColumn,
    voluntaryColumn,
    govtColumn,
    // ...(shouldShowVoluntaryColumn && { voluntaryColumn }),
    totalColumn,
    cumulativeTotalColumn,
  };

  // useful for displaying the data in a table
  const allColumnsArray: TransactionTableData[] =
    Object.values(allColumnsObject);

  // const isPartner = client === 'partner';
  // const initialAge = getMainOrPartnerInitialAge(clientData, isPartner);
  const initialAge = getInitialAge(clientData);
  const rows = makeGridRows(maxLength, allColumnsArray, initialAge);
  const columns = makeGridColumns(allColumnsArray, clientData);

  return { rows, columns, allColumnsArray, allColumnsObject };
};

const makeMainOrPartnerAgeYearColumns = (
  clientData: ClientData,
  client?: MainOrPartnerOrBoth
) => {
  const data = getKiwiSaver(clientData);
  const maxLength = data.totals.total.length;
  const { agesArray, ageColumn } = makeAgeGridData(maxLength);
  const { yearColumn } = makeYearGridData(clientData, agesArray);

  return {
    yearColumn,
    ageColumn:
      client !== 'partner'
        ? ageColumn
        : makePartnerAgeGridData(clientData, agesArray).ageColumn,
  };
};
