import { makeYearArray } from 'src/calc/age/makeYearArray';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';

// returns an array of rows to formatted for DataGrid
// each row is an object containing entries { <column.id>: number }
export const makeYearGridData = (
  clientData: ClientData,
  agesArray: number[]
) => {
  const yearArray = makeYearArray(clientData, agesArray);
  const yearColumn: TransactionTableData = {
    id: 'year',
    title: 'Year',
    array: yearArray,
  };
  return { yearArray, yearColumn };
};
