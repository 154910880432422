import dayjs from 'dayjs';
import {
  getInitialAge,
  getPartnerInitialAge,
  getYearAtPartnerAge,
  getYearsPassed,
} from 'src/calc/age/getInitialAge';
import type { Age, ClientData, PartnerAge, YYYY } from 'src/clients/types';

export const getClientAge = ({ birthday }: ClientData): Age =>
  getYearsPassed(birthday);

export const getPartnerAge = ({ partner }: ClientData): PartnerAge =>
  partner && getYearsPassed(partner.birthday);

/**
 * 12/04/2022
 * After implementing start_date, the age diff needs to account for it.
 *
 * Since getInitialAge already accounts for start_date for both main client and partner, we can safely use it to calculate age diff.
 */
export const getAgeDiff = (clientData: ClientData): Age =>
  clientData.partner
    ? Number(getPartnerInitialAge(clientData)) - getInitialAge(clientData)
    : 0;

/**
 * 12/04/2022
 * After implementing start_date, ageAtYear looks at the same DD/MM from start_date, but in the given year
 */
export const getAgeAtYear = (
  { birthday, start_date }: ClientData,
  year: YYYY
): Age => getYearsPassed(birthday, dayjs(start_date).year(year));

export const getPartnerAgeAtYear = (
  { start_date, partner }: ClientData,
  year: YYYY
): PartnerAge =>
  partner && getYearsPassed(partner.birthday, dayjs(start_date).year(year));

export const getAgeAtPartnerAge = (client: ClientData, partnerAge: Age) =>
  client.partner &&
  getAgeAtYear(client, getYearAtPartnerAge(client, partnerAge)!);
