import { Divider, Typography } from '@material-ui/core';
import { get } from 'lodash';
import type { FC } from 'react';
import type { FunctionFieldProps, ShowProps } from 'react-admin';
import {
  BooleanField,
  DateField,
  FunctionField,
  NumberField,
  Show,
  Tab,
  TextField,
  useLocale,
} from 'react-admin';
import {
  getInitialAge,
  getInitialYear,
  getPartnerInitialAge,
} from 'src/calc/age/getInitialAge';
import {
  getPartnerRetirementYear,
  getRetirementYear,
} from 'src/calc/age/getRetirementAge';
import { AssetList } from 'src/clients/assets/AssetList';
import { ClientShowActions } from 'src/clients/ClientShow/ClientShowActions';
import { ClientTabbedShowLayout } from 'src/clients/ClientShow/ClientTabbedShowLayout';
import { useClientPageTitle } from 'src/clients/hooks/useClientPageTitle';
import { useGetClientById } from 'src/clients/hooks/useGetClientById';
import { useAutoUpdateClientGlobals } from 'src/clients/hooks/useUpdateClientGlobals';
import { IncomeList } from 'src/clients/income/IncomeList';
import { useUnloadSnapshot } from 'src/clients/snapshots/hooks';
import { SnapshotList } from 'src/clients/snapshots/SnapshotList';
import { TablesMain } from 'src/clients/tables/TablesMain';
import { TransactionList } from 'src/clients/transactions/TransactionList';
import type { ClientData } from 'src/clients/types';
import { renderPercentage } from 'src/utils/renders';

export const ClientShow: FC<ShowProps> = (props) => {
  const locale = useLocale();
  const { id } = props;
  const title = useClientPageTitle(id);

  useAutoUpdateClientGlobals(id);
  useUnloadSnapshot();

  const hasPartner = !!useGetClientById(id)?.partner;

  return (
    <Show actions={<ClientShowActions />} {...{ ...props, title }}>
      <ClientTabbedShowLayout>
        <Tab label="Info" path="">
          {/* To style this view, use classes, Grid won't work */}
          <Typography variant="h6">Main Client</Typography>
          <Divider />
          <TextField source="name" />
          <Divider />
          <DateField source="birthday" locales={locale} />
          <Divider />
          <DateField source="start_date" locales={locale} />
          <Divider />
          <FunctionField<ClientData>
            source="initial_age"
            render={renderInitialAge}
          />
          {/* <FunctionField<ClientData>
            source="initial_age"
            render={renderAgeYear}
          /> */}
          <Divider />
          <FunctionField<ClientData>
            source="retirement_age"
            render={renderAgeYear}
          />

          {/* Partner fields */}
          {/* Hidden if there is no partner. Due to how RA pass data down we can't use fragments, so we need to repeat ourselves a bit here  */}
          {hasPartner && <Divider style={{ marginBottom: '2rem' }} />}
          {hasPartner && <Typography variant="h6">Partner</Typography>}
          {hasPartner && <Divider />}
          {hasPartner && <NumberField source="partner.name" label="Name" />}
          {hasPartner && <Divider />}
          {hasPartner && (
            <DateField source="partner.birthday" locales={locale} />
          )}
          {hasPartner && <Divider />}
          {hasPartner && (
            <FunctionField<ClientData>
              source="partner.initial_age"
              render={renderInitialAge}
            />
          )}
          {hasPartner && <Divider />}
          {hasPartner && (
            <FunctionField<ClientData>
              source="partner.retirement_age"
              render={renderAgeYear}
            />
          )}
          <Divider style={{ marginBottom: '2rem' }} />
          <Typography variant="h6">Settings</Typography>
          <Divider />
          <TextField source="portfolio.name" />
          <Divider />
          <BooleanField source="use_global_inflation" />
          <FunctionField<ClientData>
            source="inflation_rate"
            render={renderPercentage()}
            textAlign="right"
            label="Inflation rate"
          />
          <BooleanField source="nz_super_auto" />
        </Tab>
        <Tab label="Assets" path="assets">
          <AssetList />
        </Tab>
        <Tab label="Salary" path="salary">
          <IncomeList />
        </Tab>
        <Tab label="Retirement" path="retirement">
          <TransactionList retirement />
        </Tab>
        <Tab label="Transactions" path="transactions">
          <TransactionList />
        </Tab>
        <Tab label="Tables" path="tables">
          <TablesMain />
        </Tab>
        <Tab label="Snapshots" path="snapshots">
          <SnapshotList />
        </Tab>
        <Tab label="Misc" path="misc">
          <TextField source="id" />
          <TextField source="createdby" />
          <DateField source="createdate" locales={locale} />
          <TextField source="updatedby" />
          <DateField source="lastupdate" locales={locale} />
        </Tab>
      </ClientTabbedShowLayout>
    </Show>
  );
};

const renderAgeYear: FunctionFieldProps<ClientData>['render'] = (
  clientData,
  source
) => {
  if (!clientData || !source) return '';
  const year =
    source === 'initial_age'
      ? getInitialYear(clientData)
      : source === 'retirement_age'
      ? getRetirementYear(clientData)
      : source === 'partner.retirement_age'
      ? getPartnerRetirementYear(clientData)
      : '';
  return `${get(clientData, source)} (${year})`;
};

const renderInitialAge: FunctionFieldProps<ClientData>['render'] = (
  clientData,
  source
) => {
  if (!clientData) return '';

  return source === 'partner.initial_age'
    ? getPartnerInitialAge(clientData)
    : getInitialAge(clientData);
};
