import { addArrays } from 'src/calc/utils/addArrays';
import type { EntryTypeExpanded } from 'src/clients/transactions/types';

export type AssetOrTitle = 'asset' | 'title';

/**
 * combines transactions with same title so they are calcualted as one
 * e.g. saalary from age 40 to 50 and salary from age 51 to 60 are combined into a single transaction/array from age 40 to 60 adding up the values
 *
 * @param entries
 * @returns
 */

export const combineEntries = <T extends EntryTypeExpanded>(
  entries: T[],
  combineBy: AssetOrTitle = 'asset'
): T[] =>
  entries.reduce((combinedArray, entry) => {
    // find entry for same asset/title
    // we return the index to be able to re-insert the entry in the same position later
    const index = combinedArray.findIndex(
      ({ [combineBy]: assetOrTitle }) => assetOrTitle === entry[combineBy]
    );

    // no entry for same asset found in array, add entry as is
    if (index === -1) return [...combinedArray, entry];

    // entry for same asset found
    const existingEntry = combinedArray[index];
    const combinedEntry = {
      ...existingEntry,
      array: addArrays(existingEntry.array || [], entry.array || []),
    };

    return [
      ...combinedArray.slice(0, index),
      combinedEntry,
      ...combinedArray.slice(index + 1, combinedArray.length),
    ];
  }, [] as T[]);
