import type ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export const downloadSheet = async (workbook: ExcelJS.Workbook) => {
  try {
    const { title, xlsx } = workbook;
    const buffer = await xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
    saveAs(blob, `${title}.xlsx`);
  } catch (err) {
    console.error(err);
  }
};
