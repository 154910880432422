import type { Theme } from '@material-ui/core';
import { MenuItem, useMediaQuery } from '@material-ui/core';
import { MenuBook } from '@material-ui/icons';
import LabelIcon from '@material-ui/icons/Label';
import DefaultIcon from '@material-ui/icons/ViewList';
import type { FC } from 'react';
import type { MenuProps } from 'react-admin';
import { MenuItemLink } from 'react-admin';
import { useSelector } from 'react-redux';

// const excludesFromMenu = ['globals'];

export const Menu: FC<MenuProps> = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);

  const docsUrl = 'https://calculator-docs.mifinancialplanning.co.nz';
  const openDocs = () => window.open(docsUrl);

  return (
    <div>
      <MenuItemLink
        key={'clients'}
        to={`/clients`}
        primaryText={'Clients'}
        leftIcon={<DefaultIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      {/* Default links for resources... but we don't need those */}
      {/* {resources.map((resource) =>
        excludesFromMenu.includes(resource.name) ? null : (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        )
      )} */}

      {/* the globals are defined in a single document */}
      <MenuItemLink
        to="/globals/xRBzJU4CZNV4WMk1tcoj/show"
        primaryText="Globals"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/profile"
        primaryText="Profile"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItem onClick={openDocs}>
        <MenuBook color="primary" style={{ marginRight: 16 }} />
        Documentation
      </MenuItem>
      {isXSmall && logout}
    </div>
  );
};
