import { Typography } from '@material-ui/core';
import type { FC } from 'react';
import type { EditProps } from 'react-admin';
import {
  DateInput,
  ArrayInput,
  Edit,
  FormTab,
  NumberInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { PercentInput } from 'src/components/forms/PercentInput';
import { GlobalEditToolbar } from 'src/globals/GlobalEditToolbar';

export const GlobalEdit: FC<EditProps> = (props) => (
  <Edit {...props} title="Global Variables - Edit" hasShow={false}>
    <TabbedForm redirect={false} toolbar={<GlobalEditToolbar />}>
      <FormTab label="Main" path="">
        <PercentInput source="inflation_rate" label="Inflation Rate (%)" />
        <ArrayInput source="income_tax_rates">
          <SimpleFormIterator>
            <NumberInput
              onWheel={({ target }) => (target as HTMLInputElement).blur()}
              source="ceiling"
              label="Up to $"
            />
            <PercentInput source="rate" label="Tax Rate (%)" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Portfolios" path="portfolios">
        <ArrayInput source="portfolios">
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
            <Typography>1 year Estimates</Typography>
            <PercentInput source="year1.min_return" label="Min Return (%)" />
            <PercentInput source="year1.avg_return" label="Avg Return (%)" />
            <PercentInput source="year1.max_return" label="Max Return (%)" />
            <Typography>5 year Estimates</Typography>
            <PercentInput source="year5.min_return" label="Min Return (%)" />
            <PercentInput source="year5.avg_return" label="Avg Return (%)" />
            <PercentInput source="year5.max_return" label="Max Return (%)" />
            <Typography>10 year Estimates</Typography>
            <PercentInput source="year10.min_return" label="Min Return (%)" />
            <PercentInput source="year10.avg_return" label="Avg Return (%)" />
            <PercentInput source="year10.max_return" label="Max Return (%)" />
            <Typography>20 year Estimates</Typography>
            <PercentInput source="year20.min_return" label="Min Return (%)" />
            <PercentInput source="year20.avg_return" label="Avg Return (%)" />
            <PercentInput source="year20.max_return" label="Max Return (%)" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="KiwiSaver" path="kiwisaver">
        <ArrayInput source="kiwisaver.rates">
          <SimpleFormIterator>
            <PercentInput source="contribution" label="Contribution (%)" />
          </SimpleFormIterator>
        </ArrayInput>
        <PercentInput
          source="kiwisaver.govt_rate"
          label="Govt contribution (%)"
        />
        <NumberInput
          onWheel={({ target }) => (target as HTMLInputElement).blur()}
          source="kiwisaver.govt_max_amount"
        />
      </FormTab>
      <FormTab label="NZSuper" path="nzsuper">
        <DateInput source="nz_super.base_date" />
        <ArrayInput source="nz_super.rates">
          <SimpleFormIterator disableAdd={true} disableRemove={true}>
            <TextInput
              source="condition"
              label="Qualifying as"
              disabled
              InputProps={{ style: { fontSize: '0.75rem' } }}
            />
            <NumberInput
              onWheel={({ target }) => (target as HTMLInputElement).blur()}
              source="annual_rate"
              label="Annual Rate"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);
