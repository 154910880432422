import type { GridComparatorFn } from '@material-ui/data-grid';

/**
 * Helper used to sort transaction input table by age
 *
 * Prioritises single transactions over recurring transactions
 */

export const ageColumnSortComparator: GridComparatorFn = (
  _,
  __,
  { getValue: cellA },
  { getValue: cellB }
) =>
  // sorts by lower year_from
  (cellA('year_from') as number) < (cellB('year_from') as number)
    ? -1
    : (cellA('year_from') as number) > (cellB('year_from') as number)
    ? 1
    : // if equal, favours non-recurring
    !cellA('year_to')
    ? -1
    : !cellB('year_to')
    ? 1
    : // if both recurring, favours lower year_to
      (cellA('year_to') as number) - (cellB('year_to') as number);
