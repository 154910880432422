import type { FC } from 'react';
import React from 'react';
import type { ShowProps } from 'react-admin';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useLocale,
} from 'react-admin';
import type { ClientData } from 'src/clients/types';
import { renderPercentage } from 'src/utils/renders';

export const GlobalShow: FC<ShowProps> = (props) => {
  const locale = useLocale();
  return (
    <Show {...props} title="Global Variables">
      <TabbedShowLayout>
        <Tab label="Main" path="">
          <FunctionField<ClientData>
            source="inflation_rate"
            render={renderPercentage()}
            textAlign="right"
            label="Inflation rate"
          />
          <ArrayField source="income_tax_rates">
            <Datagrid>
              <NumberField source="ceiling" label="Up to $" />
              <FunctionField<ClientData>
                source="rate"
                render={renderPercentage()}
                textAlign="right"
                label="Tax Rate"
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Portfolios" path="portfolios">
          <ArrayField source="portfolios">
            <Datagrid optimized>
              <TextField source="name" />
              {/* <FunctionField */}
              <FunctionField<ClientData>
                source="year1.min_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="1Y Poor"
              />
              <FunctionField<ClientData>
                source="year1.avg_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="1Y Likely"
              />
              <FunctionField<ClientData>
                source="year1.max_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="1Y Good"
              />
              <FunctionField<ClientData>
                source="year5.min_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="5Y Poor"
              />
              <FunctionField<ClientData>
                source="year5.avg_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="5Y Likely"
              />
              <FunctionField<ClientData>
                source="year5.max_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="5Y Good"
              />
              <FunctionField<ClientData>
                source="year10.min_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="10Y Poor"
              />
              <FunctionField<ClientData>
                source="year10.avg_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="10Y Likely"
              />
              <FunctionField<ClientData>
                source="year10.max_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="10Y Good"
              />
              <FunctionField<ClientData>
                source="year20.min_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="20Y Poor"
              />
              <FunctionField<ClientData>
                source="year20.avg_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="20Y Likely"
              />
              <FunctionField<ClientData>
                source="year20.max_return"
                render={renderPercentage(1, 1)}
                textAlign="right"
                label="20Y Good"
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="KiwiSaver" path="kiwisaver">
          <ArrayField source="kiwisaver.rates">
            <Datagrid>
              <FunctionField<ClientData>
                source="contribution"
                render={renderPercentage()}
                textAlign="right"
                label="Contribution"
              />
            </Datagrid>
          </ArrayField>
          <FunctionField<ClientData>
            source="kiwisaver.govt_rate"
            render={renderPercentage()}
            textAlign="right"
            label="Govt contribution"
          />
          <NumberField source="kiwisaver.govt_max_amount" />
        </Tab>
        <Tab label="NZSuper" path="nzsuper">
          <DateField source="nz_super.base_date" />
          <ArrayField source="nz_super.rates">
            <Datagrid>
              <TextField source="condition" label="Qualifying as" />
              <NumberField source="annual_rate" label="Annual Rate" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Misc" path="misc">
          <TextField source="id" />
          <TextField source="createdby" />
          <DateField source="createdate" locales={locale} />
          <TextField source="updatedby" />
          <DateField source="lastupdate" locales={locale} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
