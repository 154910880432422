import { Checkbox, FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import { useState } from 'react';
import { DateInput, TextInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import { AgeInput } from 'src/components/forms/AgeInput';

export const PartnerForm: React.FC = () => {
  const { values } = useFormState();

  const [hasPartner, setHasPartner] = useState<boolean>(values?.partner?.name);
  return (
    <>
      <FormControlLabel
        label="Has Partner?"
        control={
          <Checkbox
            defaultChecked={values?.partner?.name}
            onChange={({ target: { checked } }) => {
              setHasPartner(checked);
              if (values.partner?.name) values.partner.name = null;
              values.partner = null;
            }}
          />
        }
      />
      {hasPartner && (
        <FormGroup>
          <Grid container spacing={4}>
            <Grid item md={4}>
              <TextInput source="partner.name" />
            </Grid>
            <Grid item md={4}>
              <DateInput source="partner.birthday" />
            </Grid>
            <Grid item md={4}>
              <AgeInput
                source="partner.retirement_age"
                dateSource="partner.birthday"
                defaultValue={65}
              />
            </Grid>
          </Grid>
        </FormGroup>
      )}
    </>
  );
};
