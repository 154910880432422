import { makeAgesArray } from 'src/calc/age/makeAgesArray';
import { findMaxEntryLength } from 'src/calc/transactions/findMaxEntryLength';
import { getTransactions } from 'src/calc/transactions/getTransactions';
import { getCumulativeSumByAgeArray } from 'src/calc/utils/getCumulativeSumByAgeArray';
import type { Transaction } from 'src/clients/transactions/types';
import type { ClientData } from 'src/clients/types';

export const getWithdrawals = (clientData: ClientData) => {
  const filterFn = ({ type }: Transaction) => type === 'Withdrawal';

  const withdrawals = getTransactions(clientData, filterFn);

  // helpers for calculating totals
  const maxLength = findMaxEntryLength(withdrawals.adjusted);
  const agesArray = makeAgesArray(maxLength);

  // totals arrays
  const yearlyTotal = agesArray.map((age) =>
    withdrawals.adjusted.reduce((acc, { array }) => acc + (array[age] ?? 0), 0)
  );
  const cumulativeTotal = getCumulativeSumByAgeArray(agesArray, yearlyTotal);

  return {
    transactions: withdrawals,
    totals: { yearly: yearlyTotal, cumulative: cumulativeTotal },
  };
};
