import { Button, Grid, Typography } from '@material-ui/core';
import type { VFC } from 'react';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { unstable_batchedUpdates } from 'react-dom';
import { Prompt } from 'react-router-dom';
import { getInitialAge } from 'src/calc/age/getInitialAge';
import {
  generateRandomReturns,
  MAX_ITERATIONS,
} from 'src/calc/returns/generateRandomReturns';
import { getAssets } from 'src/calc/transactions/getAssets';
import { getCashflow } from 'src/calc/transactions/getCashflow';
import { useInputDataGridStyles } from 'src/clients/assets/AssetListLive';
import { useUpdateReturns } from 'src/clients/hooks/useUpdateReturns';
import { useSnapshotOrRecordContext } from 'src/clients/snapshots/hooks';
import { BarGraph, makeChartData } from 'src/clients/tables/BarGraph';
import { makeCashflowGridData } from 'src/clients/tables/functions/makeCashflowGridData';
import { percentFormatter } from 'src/clients/tables/functions/makeCashflowReportGridData';
import { currencyFormatter } from 'src/clients/tables/functions/makeGridColumns';
import { TableBase } from 'src/clients/tables/TableBase';
import type { Client, ClientData } from 'src/clients/types';

type CalculationStatus = 'saved' | 'simulating' | 'unsaved';

export const TableCashflow: VFC = () => {
  const classes = useInputDataGridStyles();
  const { clientData, isSnapshot } = useSnapshotOrRecordContext();

  // RERUN SIMULATION
  const [marketReturns, setMarketReturns] = useState(clientData.returns);
  const [status, setStatus] = useState<CalculationStatus>('saved');
  const [tempClientData, setTempClientData] = useState<ClientData>(clientData);
  const [pct, setPct] = useState('0%');

  const run = () => {
    setStatus('simulating');
    //timeout hack to force the state update above to register
    setTimeout(async () => {
      const returns = await generateRandomReturns({
        portfolio: clientData.portfolio,
        startYear: getInitialAge(clientData),
        endYear: cashflowData.net.length,
        async: true,
        reportCallback: (i) =>
          setPct(percentFormatter(i / (MAX_ITERATIONS * 3), 0)),
      });

      unstable_batchedUpdates(() => {
        setStatus('unsaved');
        setMarketReturns(returns);
        setTempClientData((clientData) => ({ ...clientData, returns }));
        setPct('0');
      });
    }, 0);
  };

  const cashflowData = getCashflow(tempClientData);
  const { rows, columns } = makeCashflowGridData(tempClientData);
  // SAVE RETURNS
  const record: Client = useRecordContext();
  const { updateReturns } = useUpdateReturns(record);
  const save = async () => {
    if (status === 'unsaved') {
      await updateReturns(marketReturns);
      setStatus('saved');
    }
  };

  // GRAHP DATA
  const initialAge = getInitialAge(clientData);
  const data = marketReturns
    ? makeChartData(cashflowData).filter(({ year }) => year >= initialAge)
    : [];

  if (!record) return null;
  const { total } = getAssets(record);
  return (
    <>
      <Prompt
        when={status === 'simulating'}
        message="If you leave the page, simulations will stop. Are you sure?"
      />
      <Prompt
        when={status === 'unsaved'}
        message="If you leave the page, you'll lose unsaved simulation results. Are you sure?"
      />

      <Grid container direction="column" spacing={4}>
        <Grid item style={{ alignSelf: 'center' }}>
          <BarGraph {...{ data }} />
        </Grid>
        {!isSnapshot && (
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={run}
                disabled={status === 'simulating'}
              >
                {status === 'simulating'
                  ? `Simulating... ${pct}`
                  : 'Rerun simulation'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={save}
                disabled={status !== 'unsaved'}
              >
                Save simulation
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Typography variant="subtitle2" className={classes.total}>
            Initial Assets: {currencyFormatter(total)}
          </Typography>
          <TableBase {...{ rows, columns }} />
        </Grid>
      </Grid>
    </>
  );
};
