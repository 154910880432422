import type { VFC } from 'react';
import { useSnapshotOrRecordContext } from 'src/clients/snapshots/hooks';
import { makeWithdrawalsGridData } from 'src/clients/tables/functions/makeWithdrawalsGridData';
import { TableBase } from 'src/clients/tables/TableBase';

export const TableWithdrawals: VFC = () => {
  const { clientData } = useSnapshotOrRecordContext();
  if (!clientData) return null;

  const gridData = makeWithdrawalsGridData(clientData);

  return <TableBase {...gridData} />;
};
