import { Container, Drawer } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import type { VFC } from 'react';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { getInitialAge } from 'src/calc/age/getInitialAge';
import { useInputDataGridStyles } from 'src/clients/assets/AssetListLive';
import { useUpdateArrayElements } from 'src/clients/hooks/useUpdateArrayElements';
import { makeIncomeColumns } from 'src/clients/income/functions/makeIncomeColumns';
import { makeNewIncomeEntry } from 'src/clients/income/functions/makeNewIncomeEntry';
import { IncomeForm } from 'src/clients/income/IncomeForm';
import type { IncomeEntry } from 'src/clients/income/types';
import type { Client } from 'src/clients/types';
import { AddButton } from 'src/components/buttons/AddButton';
import { DeleteButton } from 'src/components/buttons/DeleteButton';
export const IncomeListLive: VFC = () => {
  //[Data Grid - Rendering - Material-UI](https://material-ui.com/components/data-grid/rendering/#column-definitions)

  const classes = useInputDataGridStyles();

  const record: Client = useRecordContext();
  if (!record) return null;

  const { incomeEntries = [] } = record;
  const [currentEntry, setCurrentEntry] = useState<IncomeEntry>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const editRow = (rowId: string) => {
    setCurrentEntry(incomeEntries.find(({ id }) => id === rowId));
    setDrawerOpen(true);
  };

  const [selected, setSelected] = useState<IncomeEntry['id'][]>([]);
  const { deleteIncomeEntries, response } = useUpdateArrayElements(record);
  const deleteSelectedRows = () => {
    deleteIncomeEntries(selected);
  };

  const addRow = () => {
    setCurrentEntry(
      makeNewIncomeEntry({
        year_from: getInitialAge(record),
        year_to: getInitialAge(record),
      })
    );
    setDrawerOpen(true);
  };

  const onClose = () => setDrawerOpen(false);

  //[React-admin - The List View](https://marmelab.com/react-admin/List.html#rendering-datagrid-with-a-custom-query)
  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={onClose}
        className={classes.drawer}
      >
        {currentEntry && (
          <Container>
            <IncomeForm transaction={currentEntry} onClose={onClose} />
          </Container>
        )}
      </Drawer>
      <DataGrid
        columns={makeIncomeColumns(record)}
        rows={incomeEntries}
        rowHeight={40}
        autoHeight
        density="compact"
        checkboxSelection
        disableSelectionOnClick
        onRowClick={({ id }) => editRow(id as string)}
        onSelectionModelChange={({ selectionModel }) =>
          setSelected(selectionModel as string[])
        }
        sortModel={[
          { field: 'partner', sort: 'asc' },
          { field: 'age', sort: 'asc' },
        ]}
        className={classes.datagrid}
      />
      <AddButton onClick={addRow} />
      {!!selected.length && <DeleteButton onClick={deleteSelectedRows} />}
    </>
  );
};
