import { Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import type { FC } from 'react';
import { getAssets } from 'src/calc/transactions/getAssets';
import { useInputDataGridStyles } from 'src/clients/assets/AssetListLive';
import { makeAssetsColumns } from 'src/clients/assets/functions/makeAssetsColumns';
import { useLoadedSnapshotData } from 'src/clients/snapshots/hooks';
import { currencyFormatter } from 'src/clients/tables/functions/makeGridColumns';

export const AssetListSnapshot: FC = () => {
  const classes = useInputDataGridStyles();
  const clientData = useLoadedSnapshotData();
  if (!clientData) return null;
  const { assets: entries = [] } = clientData;
  const { total } = getAssets(clientData);
  return (
    <div>
      <DataGrid
        columns={makeAssetsColumns()}
        rows={entries}
        rowHeight={40}
        autoHeight
        density="compact"
        disableSelectionOnClick
        className={classes.datagrid}
        sortModel={[{ field: 'title', sort: 'asc' }]}
      />
      <Typography variant="button" className={classes.total}>
        Total: {currencyFormatter(total)}
      </Typography>
    </div>
  );
};
