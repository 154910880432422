import type ExcelJS from 'exceljs';
import type { TransactionTableData } from 'src/clients/tables/types';

export const tableDataToSheetColumn = ({
  id,
}: TransactionTableData): Partial<ExcelJS.Column> => ({
  key: id,
  width: 20,
  alignment: { vertical: 'middle', horizontal: 'center' },
});
