import { Container, Drawer } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import type { VFC } from 'react';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { getInitialAge } from 'src/calc/age/getInitialAge';
import { useInputDataGridStyles } from 'src/clients/assets/AssetListLive';
import { useUpdateArrayElements } from 'src/clients/hooks/useUpdateArrayElements';
import { isRetirementTransaction } from 'src/clients/transactions/functions/helpers';
import { makeNewTransaction } from 'src/clients/transactions/functions/makeNewTransaction';
import { makeTransactionsColumns } from 'src/clients/transactions/functions/makeTransactionsColumns';
import { TransactionForm } from 'src/clients/transactions/TransactionForm';
import type { TransactionListProps } from 'src/clients/transactions/TransactionList';
import type { Transaction } from 'src/clients/transactions/types';
import type { Client } from 'src/clients/types';
import { AddButton } from 'src/components/buttons/AddButton';
import { DeleteButton } from 'src/components/buttons/DeleteButton';

export const TransactionListLive: VFC<TransactionListProps> = ({
  retirement = false,
}) => {
  //[Data Grid - Rendering - Material-UI](https://material-ui.com/components/data-grid/rendering/#column-definitions)

  const classes = useInputDataGridStyles();

  const record: Client = useRecordContext();
  if (!record) return null;

  const { transactions = [] } = record;
  const rows = transactions.filter(
    ({ type }) => retirement === isRetirementTransaction(type)
  );

  const [transaction, setTransaction] = useState<Transaction>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const editRow = (rowId: string) => {
    setTransaction(transactions.find(({ id }) => id === rowId));
    setDrawerOpen(true);
  };

  const [selected, setSelected] = useState<Transaction['id'][]>([]);
  const { deleteTransactions, response } = useUpdateArrayElements(record);
  const deleteSelectedRows = () => {
    deleteTransactions(selected);
  };

  const addRow = () => {
    setTransaction(
      makeNewTransaction({
        ...(retirement && { type: 'Retirement Budget' }),
        year_from: retirement ? record.retirement_age : getInitialAge(record),
      })
    );
    setDrawerOpen(true);
  };

  const onClose = () => setDrawerOpen(false);

  //[React-admin - The List View](https://marmelab.com/react-admin/List.html#rendering-datagrid-with-a-custom-query)
  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={onClose}
        className={classes.drawer}
      >
        {transaction && (
          <Container>
            <TransactionForm {...{ transaction, retirement, onClose }} />
          </Container>
        )}
      </Drawer>
      <div>
        <DataGrid
          columns={makeTransactionsColumns()}
          rows={rows}
          rowHeight={40}
          autoHeight
          density="compact"
          checkboxSelection
          disableSelectionOnClick
          onRowClick={({ id }) => editRow(id as string)}
          onSelectionModelChange={({ selectionModel }) =>
            setSelected(selectionModel as string[])
          }
          className={classes.datagrid}
          sortModel={[
            { field: 'type', sort: 'asc' },
            { field: 'age', sort: 'asc' },
            { field: 'asset', sort: 'asc' },
          ]}
        />
      </div>
      <AddButton onClick={addRow} />
      {!!selected.length && <DeleteButton onClick={deleteSelectedRows} />}
    </>
  );
};
