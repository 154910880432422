import type { ButtonProps } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { AddSharp } from '@material-ui/icons';
import type { VFC } from 'react';
import { useListButtonStyles } from 'src/components/buttons/DeleteButton';

export const AddButton: VFC<ButtonProps> = (props) => (
  <Button
    {...props}
    color="primary"
    variant="contained"
    className={useListButtonStyles().tableButton}
    startIcon={<AddSharp />}
  >
    Add
  </Button>
);
