import type { GridColumns } from '@material-ui/data-grid';
import { snapshotDateFormatter } from 'src/clients/snapshots/SnapshotForm';
import { HiddenBooleanCell } from 'src/clients/transactions/RenderCells';

/** Input table columns */
export const makeSnapshotsColumns = (): GridColumns => [
  {
    field: 'title',
    headerName: 'Title',
    width: 600,
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 300,
    valueFormatter: ({ value }) => snapshotDateFormatter(value as string),
  },
  {
    field: 'loaded',
    headerName: 'Loaded',
    width: 100,
    renderCell: HiddenBooleanCell,
  },
];
