import { v4 } from 'uuid';
import type { Transaction } from 'src/clients/transactions/types';

export const makeNewTransaction = (
  data?: Partial<Omit<Transaction, 'id'>>
): Transaction => ({
  id: v4(),
  title: '',
  type: 'Contribution',
  value: 0,
  year_from: 0,
  asset: 'Other',
  recurring: false,
  inflation_adjusted: false,
  ...data,
});
