import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import type { VFC } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { TableCashflow } from 'src/clients/tables/TableCashflow';
import { TableContributions } from 'src/clients/tables/TableContributions';
import { TableIncome } from 'src/clients/tables/TableIncome';
import { TableKiwisaver } from 'src/clients/tables/TableKiwisaver';
import { TableRetirement } from 'src/clients/tables/TableRetirement';
import { TableWithdrawals } from 'src/clients/tables/TableWithdrawals';

export const TablesMain: VFC = () => {
  // const [value, setValue] = React.useState('contributions');

  const history = useHistory();
  const { pathname, hash } = useLocation();
  const value = hash ? hash.replace('#', '') : 'cashflow';

  const handleChange = (event: any, newValue: string) => {
    history.push({ pathname, hash: newValue });
  };

  useEffect(() => {
    // hack to strip hash from url when navigating away
    return () => history.replace({});
  }, []);

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange}>
        <Tab label="Salary" value="salary" />
        <Tab label="Kiwisaver" value="kiwisaver" />
        <Tab label="Contributions" value="contributions" />
        <Tab label="Withdrawals" value="withdrawals" />
        <Tab label="Cashflow" value="cashflow" />
        <Tab label="Retirement" value="retirement" />
      </TabList>
      <TabPanel value="salary">
        <TableIncome />
      </TabPanel>
      <TabPanel value="kiwisaver">
        <TableKiwisaver />
      </TabPanel>
      <TabPanel value="contributions">
        <TableContributions />
      </TabPanel>
      <TabPanel value="withdrawals">
        <TableWithdrawals />
      </TabPanel>
      <TabPanel value="cashflow">
        <TableCashflow />
      </TabPanel>
      <TabPanel value="retirement">
        <TableRetirement />
      </TabPanel>
    </TabContext>
  );
};
