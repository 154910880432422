import { getInitialAge } from 'src/calc/age/getInitialAge';
import { getAgeAtEarliestRetirementYear } from 'src/calc/age/getRetirementAge';
import { makeAgesArray } from 'src/calc/age/makeAgesArray';
import { findMaxEntryLength } from 'src/calc/transactions/findMaxEntryLength';
import { getIncome } from 'src/calc/transactions/getIncome';
import { getNZSuperAuto } from 'src/calc/transactions/getNZSuperAuto';
import { getTransactions } from 'src/calc/transactions/getTransactions';
import { getWithdrawals } from 'src/calc/transactions/getWithdrawals';
import { getCumulativeSumByAgeArray } from 'src/calc/utils/getCumulativeSumByAgeArray';
import type { Transaction } from 'src/clients/transactions/types';
import type { ClientData } from 'src/clients/types';

export const getRetirementIncome = (clientData: ClientData) => {
  const retirementIncome = getTransactions(
    clientData,
    ({ type }: Transaction) => type === 'Income During Retirement'
  );
  const retirementBudget = getTransactions(
    clientData,
    ({ type }: Transaction) => type === 'Retirement Budget'
  );

  const yearlyWithdrawals = getWithdrawals(clientData).totals.yearly;

  const retirementAge = getAgeAtEarliestRetirementYear(clientData);
  // prevent calculations running before the start date
  const initialAge = getInitialAge(clientData);
  const ageRetirementCalculationsStart = Math.max(initialAge, retirementAge);

  const withdrawalsBeforeRetirement = fillZerosAfter(
    yearlyWithdrawals,
    ageRetirementCalculationsStart
  );
  const retirementWithdrawals = fillZerosBefore(
    yearlyWithdrawals,
    ageRetirementCalculationsStart
  );

  const NZSuperAuto = getNZSuperAuto(clientData);

  // helpers for calculating totals
  const maxLength = Math.max(
    findMaxEntryLength(retirementIncome.adjusted),
    findMaxEntryLength(retirementBudget.adjusted),
    findMaxEntryLength(NZSuperAuto.combined),
    retirementWithdrawals.length
  );
  const agesArray = makeAgesArray(maxLength);

  const allIncomeDuringRetirementTransactions = [
    ...retirementIncome.combined,
    ...NZSuperAuto.combined,
  ];

  // totals arrays
  const yearlyTotalIncome = agesArray.map((age) =>
    allIncomeDuringRetirementTransactions.reduce(
      (acc, { array }) => acc + (array[age] ?? 0),
      0
    )
  );
  const cumulativeTotalIncome = getCumulativeSumByAgeArray(
    agesArray,
    yearlyTotalIncome
  );

  const yearlyBudget = agesArray.map((age) =>
    retirementBudget.combined.reduce(
      (acc, { array }) => acc + (array[age] ?? 0),
      0
    )
  );

  // const yearlyRequired = addArrays(yearlyBudget, retirementWithdrawals);

  // As of 15/04/2022
  // salary income is used to cover shortfall while the clients still work
  const {
    totals: { totals: salaryIncome },
  } = getIncome(clientData);
  // only count retirement years
  const salaryIncomeDuringRetirement = fillZerosBefore(
    salaryIncome,
    ageRetirementCalculationsStart
  );

  // but the salary only covers the remaining budget that the income from all sources doesn't
  // we call it budgetShortfall
  // this is the one used in the cashflow calculations, to avoid double-counting withdrawals
  const budgetShortfall = agesArray.map((age) => {
    if (age < ageRetirementCalculationsStart) return 0;

    const budget = yearlyBudget[age] ?? 0;
    const income = yearlyTotalIncome[age] ?? 0;

    const net = income - budget;

    // As of 15/04/2022
    // consider salary from working partner(s) to dampen the shortfall
    const salary = salaryIncomeDuringRetirement[age] ?? 0;
    const netWithSalary = net >= 0 ? net : net + salary;
    // but don't account for extra salary as a surplus
    const shortfall = net >= 0 ? net : Math.min(netWithSalary, 0);

    return shortfall;
  });

  // the actual shortfall displayed on the table accounts for withdrawals
  const shortfall = agesArray.map(
    (age) => (budgetShortfall[age] ?? 0) - (retirementWithdrawals[age] ?? 0)
  );

  return {
    transactions: retirementIncome,
    NZSuperAuto,
    totals: {
      yearlyIncome: yearlyTotalIncome,
      cumulativeIncome: cumulativeTotalIncome,
      // required: yearlyRequired,
      budget: yearlyBudget,
      withdrawals: retirementWithdrawals,
      withdrawalsBeforeRetirement,
      budgetShortfall,
      shortfall,
      salary: salaryIncomeDuringRetirement,
    },
  };
};

const fillZerosBefore = (array: number[], n: number) =>
  [...array].fill(0, 0, n);
const fillZerosAfter = (array: number[], n: number) => [...array].fill(0, n);
