import type ExcelJS from 'exceljs';
import { getInitialAge } from 'src/calc/age/getInitialAge';
import type { MainOrPartnerOrBoth } from 'src/calc/transactions/getIncome';
import { makeKiwisaverGridData } from 'src/clients/tables/functions/makeKiwisaverGridData';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';
import { tableDataToSheetColumn } from 'src/export/tableDataToSheetColumn';
import { tableDataToSheetRows } from 'src/export/tableDataToSheetRows';

/**
 * Sheet with both Main client and Partner, side by side
 */
export const addKiwisaverSideBySideSheet = (
  workbook: ExcelJS.Workbook,
  clientData: ClientData,
  client?: MainOrPartnerOrBoth
) => {
  const worksheet = workbook.addWorksheet(
    `Kiwisaver${client ? `-${client}` : ''}`
  );

  // track on which row we are
  let rowCursor = 1;

  const mainClientColumns = getMainOrPartnarColumns(clientData, 'main');
  const partnerColumns = getMainOrPartnarColumns(clientData, 'partner');
  const allColumns = [...mainClientColumns, ...partnerColumns];

  // define sheet columns, each column has an id, later to be used for populating the values
  worksheet.columns = allColumns.map(tableDataToSheetColumn);

  // top Row
  worksheet.getRow(rowCursor).values = ['', 'KiwiSaver'];
  worksheet.mergeCells('B1:O1');
  worksheet.getRow(rowCursor).alignment = { horizontal: 'center' };
  worksheet.getRow(rowCursor).font = { bold: true };

  rowCursor = 2;
  // main/partner
  const mainName = clientData.name;
  const partnerName = clientData.partner?.name;
  const noOfColumns = mainClientColumns.length;
  worksheet.getCell(rowCursor, 2).value = mainName;
  worksheet.getCell(rowCursor, noOfColumns + 1).value = partnerName;
  worksheet.mergeCells(rowCursor, 2, rowCursor, noOfColumns);
  worksheet.mergeCells(
    rowCursor,
    noOfColumns + 1,
    rowCursor,
    2 * noOfColumns - 1
  );
  worksheet.getRow(rowCursor).alignment = { horizontal: 'center' };
  worksheet.getRow(rowCursor).font = { bold: true };

  rowCursor = 3;

  // headers
  worksheet.getRow(rowCursor).values = allColumns.map(({ title }) => title);
  worksheet.getRow(rowCursor).alignment = { horizontal: 'center' };
  worksheet.getRow(rowCursor).font = { bold: true };
  worksheet.getRow(rowCursor).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F0F0F0' },
  };

  // POPULATE ROWS
  // for each age... (allColumns[0].array == ages)
  const initialAge = getInitialAge(clientData);
  rowCursor = tableDataToSheetRows({
    allColumns,
    worksheet,
    rowCursor,
    initialAge,
    ageColumns: [2, 9],
  });

  return rowCursor;
};

const getMainOrPartnarColumns = (
  clientData: ClientData,
  client: 'main' | 'partner'
) => {
  const {
    yearColumn,
    ageColumn,
    salaryColumn,
    employeeColumn,
    employerColumn,
    voluntaryColumn,
    govtColumn,
    totalColumn,
  } = makeKiwisaverGridData(clientData, client).allColumnsObject;

  // client has requested to remove cumulativeTotal from export
  const columnsArray: TransactionTableData[] = [
    ...(client === 'main' ? [yearColumn] : []),
    ageColumn,
    salaryColumn,
    employeeColumn,
    employerColumn,
    voluntaryColumn,
    govtColumn,
    totalColumn,
  ];

  // requirement for excel
  const columnsPrefixed = prefixColumnIds(columnsArray, client);

  return columnsPrefixed;
};

/**
 * Generates unique ids for columns
 */
export const prefixColumnIds = (columns: TransactionTableData[], prefix = '') =>
  columns.map(({ id, ...rest }) => ({ id: `${prefix}_${id}`, ...rest }));
