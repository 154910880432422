import type ExcelJS from 'exceljs';
import type { TransactionTableData } from 'src/clients/tables/types';

type TableDataToSheetRowsProps = {
  allColumns: TransactionTableData[];
  worksheet: ExcelJS.Worksheet;
  rowCursor: number;
  initialAge: number;
  excludeFromFormat?: number[];
  ageColumns?: number[];
  formatAsPercentages?: number[];
};
export const tableDataToSheetRows = ({
  allColumns,
  worksheet,
  rowCursor,
  initialAge,
  excludeFromFormat = [1, 2],
  ageColumns = [2],
  formatAsPercentages = [],
}: TableDataToSheetRowsProps) => {
  const a = allColumns[0].array.slice(initialAge).forEach((_, i) => {
    // make an object of values to be inserted in the row, at their specific columns by id
    const rowData = allColumns.reduce(
      (rowObj, { id, array }) =>
        ({
          ...rowObj,
          [id]: array[i + initialAge] || 0,
        } as Partial<ExcelJS.RowValues>),
      {} as Partial<ExcelJS.RowValues>
    );

    // then merge that object into the current row
    // note that we don't add a new row, this is useful because this method can be used to edit these same rows later on for adding/editing column values, e.g. transactions
    const currentRow = worksheet.getRow(++rowCursor); // starts from row 3 (or greater depending on what sheet)
    currentRow.values = {
      ...currentRow.values,
      ...rowData,
    };

    // we need to format the row after editing its values
    // ideally we'd format the columns on creation but for some reason that didn't work...
    currentRow.alignment = { horizontal: 'center' };

    const defaultFormat = currentRow.getCell(1).numFmt; //hack to preserve column format
    currentRow.numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00'; // format columns as currency

    excludeFromFormat.forEach((column) => {
      currentRow.getCell(column).numFmt = defaultFormat; // except year
    });
    ageColumns.forEach((column) => {
      const age = parseInt(currentRow.getCell(column).value?.toString() ?? '0');
      currentRow.getCell(column).value = `${age} to ${age + 1}`;
    });
    formatAsPercentages.forEach((column) => {
      currentRow.getCell(column).numFmt = '0.00%';
    });
  });
  return rowCursor;
};
