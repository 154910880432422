import { Button } from '@material-ui/core';
import { GetApp, Visibility } from '@material-ui/icons';
import type { VFC } from 'react';
import type { ShowActionsProps } from 'react-admin';
import { EditButton, TopToolbar } from 'react-admin';
import { useHistory } from 'react-router-dom';
import {
  useLoadedSnapshot,
  useSnapshotOrRecordContext,
} from 'src/clients/snapshots/hooks';
import type { Client } from 'src/clients/types';
import { downloadSheet } from 'src/export/downloadXlsx';
import { makeXlsx } from 'src/export/makeXlsx';

type MenuChoice = { title: string; value: string };

export const ClientShowActions: VFC<ShowActionsProps> = ({
  basePath,
  data,
  resource,
}) => {
  const history = useHistory();
  const { clientData } = useSnapshotOrRecordContext();
  const snapshotTitle = useLoadedSnapshot()?.title;
  const isLiveData = !snapshotTitle;

  // EXCEL
  const handleDownload = () => downloadSheet(makeXlsx(clientData));

  return (
    <TopToolbar>
      {isLiveData ? (
        <EditButton basePath={basePath} record={data} />
      ) : (
        <Button
          startIcon={<Visibility />}
          color="primary"
          size="small"
          onClick={() => {
            history.push('snapshots');
          }}
        >
          Viewing Snapshot {snapshotTitle}
        </Button>
      )}
      {/* Add your custom actions */}
      <Button
        startIcon={<GetApp />}
        color="primary"
        size="small"
        onClick={handleDownload}
      >
        Export
      </Button>
    </TopToolbar>
  );
};
