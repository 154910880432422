import { FormGroup, Grid, Typography } from '@material-ui/core';
import { Error, Loading, SelectInput, useRecordContext } from 'react-admin';
import { useForm } from 'react-final-form';
import type { Client, ClientData } from 'src/clients/types';
import { PercentInput } from 'src/components/forms/PercentInput';
import { defaultPortfolio } from 'src/globals/defaults';
import { useGlobals } from 'src/globals/hooks';
import type { Portfolio } from 'src/globals/types';

export const PortfolioSelect: React.FC = () => {
  const { data: globals, error } = useGlobals();
  const record: Client = useRecordContext();

  const customPortfolio: Portfolio = {
    ...(record.portfolio ?? defaultPortfolio),
    name: 'Custom',
  };

  const { change } = useForm();

  if (!globals || !record) return <Loading />;
  if (error) return <Error error="Error Loading Globals" />;
  return (
    <>
      <SelectInput
        source="portfolio"
        choices={[...globals.portfolios, customPortfolio].map(({ name }) => ({
          id: name,
          name,
        }))}
        format={(portfolio: Portfolio) => portfolio?.name}
        parse={(name: string) =>
          name === 'Custom'
            ? customPortfolio
            : globals?.portfolios?.find((p) => p?.name === name)
        }
      />
      <FormGroup onChange={() => change('portfolio.name', 'Custom')}>
        <Typography>1 year Estimates</Typography>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year1.min_return"
              label="Min Return (%)"
            />
          </Grid>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year1.avg_return"
              label="Avg Return (%)"
            />
          </Grid>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year1.max_return"
              label="Max Return (%)"
            />
          </Grid>
        </Grid>
        <Typography>5 year Estimates</Typography>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year5.min_return"
              label="Min Return (%)"
            />
          </Grid>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year5.avg_return"
              label="Avg Return (%)"
            />
          </Grid>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year5.max_return"
              label="Max Return (%)"
            />
          </Grid>
        </Grid>
        <Typography>10 year Estimates</Typography>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year10.min_return"
              label="Min Return (%)"
            />
          </Grid>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year10.avg_return"
              label="Avg Return (%)"
            />
          </Grid>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year10.max_return"
              label="Max Return (%)"
            />
          </Grid>
        </Grid>
        <Typography>20 year Estimates</Typography>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year20.min_return"
              label="Min Return (%)"
            />
          </Grid>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year20.avg_return"
              label="Avg Return (%)"
            />
          </Grid>
          <Grid item md={4}>
            <PercentInput
              source="portfolio.year20.max_return"
              label="Max Return (%)"
            />
          </Grid>
        </Grid>
      </FormGroup>
    </>
  );
};
