import { cacheDataProviderProxy } from 'react-admin';
import type { RAFirebaseOptions } from 'react-admin-firebase';
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyC1dRVuL177L4r3SO7l_w83pVhFB73EIP0',
  authDomain: 'isaacs-calc.firebaseapp.com',
  projectId: 'isaacs-calc',
  storageBucket: 'isaacs-calc.appspot.com',
  messagingSenderId: '962982146950',
  appId: '1:962982146950:web:25460736c1d4704dcaf207',
};

// All options are optional
const options: RAFirebaseOptions = {
  // Use a different root document to set your resource collections, by default it uses the root collections of firestore
  // rootRef: 'root-collection/some-doc' || (() => 'root-collection/some-doc'),

  // Your own, previously initialized firebase app instance
  // app: firebaseAppInstance,

  // Enable logging of react-admin-firebase
  logging: true,

  // Resources to watch for realtime updates, will implicitly watch all resources by default, if not set.
  // watch: ['posts'],

  // Resources you explicitly dont want realtime updates for
  // dontwatch: ['comments'],

  // Authentication persistence, defaults to 'session', options are 'session' | 'local' | 'none'
  persistence: 'session',

  // Disable the metadata; 'createdate', 'lastupdate', 'createdby', 'updatedby'
  // disableMeta: false,

  // Have custom metadata field names instead of: 'createdate', 'lastupdate', 'createdby', 'updatedby'
  // renameMetaFields: {
  //   created_at: 'my_created_at', // default: 'createdate'
  //   created_by: 'my_created_by', // default: 'createdby'
  //   updated_at: 'my_updated_at', // default: 'lastupdate'
  //   updated_by: 'my_updated_by', // default: 'updatedby'
  // },

  // Prevents document from getting the ID field added as a property
  // dontAddIdFieldToDoc: false,

  // Adds 'deleted' meta field for non-destructive deleting functionality
  // NOTE: Hides 'deleted' records from list views unless overridden by filtering for {deleted: true}
  softDelete: true,

  // Changes meta fields like 'createdby' and 'updatedby' to store user IDs instead of email addresses
  // associateUsersById: false,

  // Casing for meta fields like 'createdby' and 'updatedby', defaults to 'lower', options are 'lower' | 'camel' | 'snake' | 'pascal' | 'kebab'
  // metaFieldCasing: 'lower',

  // Instead of saving full download url for file, save just relative path and then get download url
  // when getting docs - main use case is handling multiple firebase projects (environments)
  // and moving/copying documents/storage files between them - with relativeFilePaths, download url
  // always point to project own storage
  // relativeFilePaths: false,

  // Add file name to storage path, when set to true the file name is included in the path
  // useFileNamesInStorage: false

  // Use firebase sdk queries for pagination, filtering and sorting
  // lazyLoading: {
  //   enabled: true,
  // },

  // Logging of all reads performed by app (additional feature, for lazy-loading testing)
  // firestoreCostsLogger: {
  //   enabled: true,
  //   // localStoragePrefix // optional
  // },
};

// ------------------------------------
// DATA PROVIDER WITH CACHING
//------------------------------------
// https://marmelab.com/react-admin/doc/3.14/Caching.html#application-cache
//
// This sifgnificantly reduces the amount of read operations to the database, reducing costs, particularly when searching.

// vanilla provider
const rafDataProvider = FirebaseDataProvider(firebaseConfig, options);

// Make cache valid for a ridiculously long time
// The cache is automatically invalidated after a write operation
const oneYear = 1 * 365 * 24 * 60 * 1000;

// cached provider
export const dataProvider = cacheDataProviderProxy(rafDataProvider, oneYear);

export const authProvider = FirebaseAuthProvider(firebaseConfig, options);
