import { type } from '@testing-library/user-event/dist/type';
import type { IncomeEntry } from 'src/clients/income/types';
import type { NZSuperCases } from 'src/globals/types';

export const GENERAL_TRANSACTION_TYPES = [
  'Contribution',
  'Withdrawal',
] as const;
export const RETIREMENT_TRANSACTION_TYPES = [
  'Income During Retirement',
  'Retirement Budget',
] as const;
export const TRANSACTION_TYPES = [
  ...GENERAL_TRANSACTION_TYPES,
  ...RETIREMENT_TRANSACTION_TYPES,
] as const;

export type GeneralTransactionType = typeof GENERAL_TRANSACTION_TYPES[number];
export type RetirementTransactionType =
  typeof RETIREMENT_TRANSACTION_TYPES[number];
export type TransactionType =
  | GeneralTransactionType
  | RetirementTransactionType;

export type Transaction = {
  id: string;
  title: string;
  type: TransactionType;
  year_from: number; // in years from the start
  year_to?: number | null; // in years from the start
  value: number;
  recurring: boolean;
  inflation_adjusted: boolean;
  asset: string;
};

export type EntryType = Transaction | IncomeEntry;
export type Expanded<T extends EntryType> = T & { array: number[] };
export type TransactionExpanded = Transaction & {
  array: number[];
};
export type EntryTypeExpanded = Expanded<Transaction | IncomeEntry>;

export type NZSuperTransactionTitle = `NZ Super ${NZSuperCases}`;
