import { Debug, makeValidateSync, TextField } from 'mui-rff';
import type { FC } from 'react';
import { useRecordContext } from 'react-admin';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import type { Asset } from 'src/clients/assets/types';
import { useUpdateArrayElements } from 'src/clients/hooks/useUpdateArrayElements';
import type { DrawerFormProps } from 'src/clients/transactions/TransactionForm';
import { useDrawerFormStyles } from 'src/clients/transactions/TransactionForm';
import type { Client } from 'src/clients/types';
import { ResetButton } from 'src/components/buttons/ResetButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';

export type AssetFormProps = DrawerFormProps & {
  asset: Asset;
};

export const AssetForm: FC<AssetFormProps> = ({ asset, onClose }) => {
  const record: Client = useRecordContext();

  const { upsertAsset, response } = useUpdateArrayElements(record);

  const handleSubmit = (updatedAsset: Asset) => {
    upsertAsset(updatedAsset);
    onClose && onClose();
  };

  const classes = useDrawerFormStyles();
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={asset}
      subscription={{ values: true, submitting: true, pristine: true }}
      render={({ handleSubmit, values, ...props }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <TextField type="text" label="Title" name="title" />

          <TextField
            type="number"
            label="Value"
            name="value"
            fieldProps={{ parse: parseFloat }}
            onWheel={({ target }) => (target as HTMLInputElement).blur()}
          />
          {/* <FormGroup> */}
          <SubmitButton disabled={props.pristine} />
          <ResetButton
            onClick={() => props.form.reset()}
            disabled={props.pristine}
          />
          {/* </FormGroup> */}
        </form>
      )}
    ></Form>
  );
};

const schema: Yup.SchemaOf<Partial<Asset>> = Yup.object({
  id: Yup.string().required(),
  title: Yup.string().required(),
  value: Yup.number().required().min(0),
});

const validate = makeValidateSync(schema);
