import type { ThemeOptions } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { merge } from 'lodash';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import type { FC } from 'react';
import { Admin, defaultTheme, Resource } from 'react-admin';
import { clients } from 'src/clients/resource';
import type { CustomReducers } from 'src/clients/snapshots/redux';
import { snapshotsReducer } from 'src/clients/snapshots/redux';
import { Layout } from 'src/components/Layout/Layout';
import {
  authProvider,
  dataProvider,
} from 'src/config/react-admin-firebase-config';
import { globals } from 'src/globals/resource';
import { LoginPage } from 'src/pages/LoginPage/LoginPage';
import { customRoutes } from 'src/utils/customRoutes';

// https://marmelab.com/react-admin/Translation.html#specific-case-in-confirm-messages-and-empty-page
// https://github.com/marmelab/react-admin/tree/master/packages/ra-language-english
const i18nProvider = polyglotI18nProvider(
  (_) => englishMessages,
  // resolveBrowserLocale(),
  'en-NZ',
  {
    allowMissing: true,
    // https://github.com/marmelab/react-admin/issues/5727#issuecomment-778251452
    onMissingKey: (key: any, _: any, __: any) => key,
  }
);

//[React-admin - Theming](https://marmelab.com/react-admin/Theming.html#writing-a-custom-theme)
const theme = merge(defaultTheme, {
  palette: {
    primary: { main: '#83673c' },
    secondary: { main: '#2e2211' },
    error: red,
  },
} as ThemeOptions);

const customReducers: CustomReducers = { snapshot: snapshotsReducer };
const App: FC = () => (
  <Admin
    title="Makowem & Isaacs Financial Planning Calculator"
    loginPage={LoginPage}
    layout={Layout}
    {...{
      dataProvider,
      authProvider,
      customRoutes,
      customReducers,
      i18nProvider,
      theme,
    }}
  >
    <Resource {...clients} />
    <Resource {...globals} />
  </Admin>
);

export default App;
