import type { GridColumns } from '@material-ui/data-grid';
import { ageColumnSortComparator } from 'src/clients/transactions/functions/ageColumnSortComparator';
import { AgeCell, BooleanCell } from 'src/clients/transactions/RenderCells';
/** Input table columns */

export const makeTransactionsColumns = (): GridColumns => [
  {
    field: 'type',
    headerName: 'Type',
    width: 150,
    sortable: false,
  },
  {
    field: 'age',
    headerName: 'Age',
    renderCell: AgeCell,
    width: 120,
    sortable: false,
    sortComparator: ageColumnSortComparator,
  },
  {
    field: 'asset',
    headerName: 'Asset Type',
    width: 150,
    sortable: false,
  },
  {
    field: 'title',
    headerName: 'Transaction',
    width: 150,
    sortable: false,
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 150,
    sortable: false,
    valueFormatter: ({ value }) =>
      value?.toLocaleString('en-NZ', { style: 'currency', currency: 'NZD' }),
  },
  // {
  //   field: 'recurring',
  //   headerName: 'Recurring',
  //   width: 150,
  //   sortable: false,
  //   renderCell: BooleanCell,
  // },
  {
    field: 'inflation_adjusted',
    headerName: 'Inflation-Adj.',
    width: 150,
    sortable: false,
    renderCell: BooleanCell,
  },
];
