import { v4 } from 'uuid';
import type { IncomeEntry } from 'src/clients/income/types';

export const makeNewIncomeEntry = (
  data?: Partial<Omit<IncomeEntry, 'id'>>
): IncomeEntry => ({
  id: v4(),
  title: 'Salary',
  type: 'Income',
  value: 0,
  year_from: 0,
  year_to: 0,
  asset: 'Salary',
  recurring: true,
  inflation_adjusted: false,
  kiwisaver: 0,
  ...data,
});
