/**
 * Element-wise array sum
 *
 * @returns Ex [1,2,2,4] + [0,4,5,6,7] = [1,6,7,10,7]
 */

export const addArrays = (...arrays: number[][]): number[] => {
  const maxLength = Math.max(0, ...arrays.map((array) => array.length));
  return new Array(maxLength)
    .fill(0)
    .map((_, i) => arrays.reduce((acc, array) => acc + (array[i] || 0), 0));
};
