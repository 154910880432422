import type ExcelJS from 'exceljs';
import { getInitialAge } from 'src/calc/age/getInitialAge';
import { getAssets } from 'src/calc/transactions/getAssets';
import { makeCashflowReportGridData } from 'src/clients/tables/functions/makeCashflowReportGridData';
import type { ClientData } from 'src/clients/types';
import { tableDataToSheetColumn } from 'src/export/tableDataToSheetColumn';
import { tableDataToSheetRows } from 'src/export/tableDataToSheetRows';

export const addCashflowReportSheet = (
  workbook: ExcelJS.Workbook,
  clientData: ClientData
) => {
  const worksheet = workbook.addWorksheet('Cashflow Report');

  // track on which row we are
  let rowCursor = 1;
  let currentRow = worksheet.getRow(rowCursor);

  const { allColumns } = makeCashflowReportGridData(clientData);
  const { total } = getAssets(clientData);

  // define sheet columns, each column has an id, later to be used for populating the values
  worksheet.columns = allColumns.map(tableDataToSheetColumn);

  // top Row
  currentRow.values = ['', 'Cashflow'];
  worksheet.mergeCells('B1:G1');
  currentRow.alignment = { horizontal: 'center' };
  currentRow.font = { bold: true };

  rowCursor = 2;
  currentRow = worksheet.getRow(rowCursor);

  // headers
  currentRow.values = allColumns.map(({ title }) => title);
  currentRow.alignment = { horizontal: 'center' };
  currentRow.font = { bold: true };
  currentRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F0F0F0' },
  };

  // initial assets
  rowCursor = 3;
  currentRow = worksheet.getRow(rowCursor);

  currentRow.values = [, , , , , total, , total];
  currentRow.numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00'; // format
  currentRow.alignment = { horizontal: 'center' };

  // POPULATE ROWS
  // for each age... (allColumns[0].array == ages)
  const initialAge = getInitialAge(clientData);
  rowCursor = tableDataToSheetRows({
    allColumns,
    worksheet,
    rowCursor,
    initialAge,
    formatAsPercentages: [3],
  });

  return rowCursor;
};
