import type { GridColDef, GridColumns } from '@material-ui/data-grid';
import dayjs from 'dayjs';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData, YYYYMMDD } from 'src/clients/types';
// returns columns formatted for datagrid

export const makeGridColumns = (
  allColumns: TransactionTableData[],
  clientData: ClientData
): GridColumns =>
  allColumns.map(({ id, title }) => ({
    field: id,
    headerName: title,
    sortable: false,
    width: isCurrencyColumn(id) ? 200 : 100,
    valueFormatter:
      id === 'age'
        ? ageBandFormatter
        : id === 'roiPercent'
        ? roiPercentFormatter
        : isCurrencyColumn(id)
        ? gridCurrencyFormatter
        : undefined,
  }));

export const isCurrencyColumn = (id: string) =>
  !['age', 'year', 'roiPercent'].includes(id);

const ageYearFormatter =
  (birthday: YYYYMMDD): GridColDef['valueFormatter'] =>
  (params) => {
    const age = (params?.value as number) ?? 0;
    const year = dayjs(birthday).year() + age;

    return `${age} (${year})`;
  };

const ageBandFormatter: GridColDef['valueFormatter'] = (params) => {
  const age = (params?.value as number) ?? 1;

  // return `${age - 1} to ${age}`;
  return `${age} to ${age + 1}`;
};

export const gridCurrencyFormatter: GridColDef['valueFormatter'] = (params) =>
  currencyFormatter((params?.value ?? 0) as number);

export const currencyFormatter = (
  value: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
): string =>
  value.toLocaleString('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits,
    maximumFractionDigits,
  });

const roiFormatter =
  (roiPercent: number[]): GridColDef['valueFormatter'] =>
  (params) =>
    `${((params?.value ?? 0) as number).toLocaleString('en-NZ', {
      style: 'currency',
      currency: 'NZD',
    })} (${(roiPercent[params.rowIndex] * 100).toFixed(1)}%)`;

const roiPercentFormatter: GridColDef['valueFormatter'] = (params) =>
  `${(((params?.value ?? 0) as number) * 100).toFixed(2)}%`;
