import { getAgeAtYear } from 'src/calc/age/getClientAge';
import { getYearAtAge, getYearAtPartnerAge } from 'src/calc/age/getInitialAge';
import type { Age, ClientData, PartnerYYYY, YYYY } from 'src/clients/types';

export const getRetirementYear = (clientData: ClientData): YYYY =>
  getYearAtAge(clientData, clientData.retirement_age);

export const getPartnerRetirementYear = (clientData: ClientData): PartnerYYYY =>
  clientData.partner &&
  Number(getYearAtPartnerAge(clientData, clientData.partner.retirement_age));

export const getEarliestRetirementYear = (clientData: ClientData): YYYY =>
  Math.min(
    getRetirementYear(clientData),
    getPartnerRetirementYear(clientData) || getRetirementYear(clientData)
  );

export const getLatestRetirementYear = (clientData: ClientData): YYYY =>
  Math.max(
    getRetirementYear(clientData),
    getPartnerRetirementYear(clientData) || getRetirementYear(clientData)
  );

export const getAgeAtEarliestRetirementYear = (clientData: ClientData): Age =>
  getAgeAtYear(clientData, getEarliestRetirementYear(clientData));

export const getAgeAtLatestRetirementYear = (clientData: ClientData): Age =>
  getAgeAtYear(clientData, getLatestRetirementYear(clientData));
