//This is a MUI-RFF component, NOT for react-admin

import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import type { TextFieldProps } from 'mui-rff';
import { TextField } from 'mui-rff';
import type { VFC } from 'react';

const useStyles = makeStyles<Theme, { year?: number }>((theme) => ({
  input: {
    // '& .MuiFilledInput-underline:after': {
    '& .MuiInput-underline:after': {
      left: 35,
      bottom: 7,
      color: 'grey',
      content: ({ year }) => (year ? `"(${year.toString()})"` : ''),
      borderBottom: 'none',
      transform: 'none',
    },
  },
}));

export type AgeFieldProps = TextFieldProps & {
  ageDiff?: number;
  baseYear: number;
  excluding?: boolean;
};

export const AgeField: VFC<AgeFieldProps> = ({
  className,
  baseYear,
  ageDiff = 0,
  excluding,
  fieldProps,
  ...props
}) => {
  // offset adjusts internal value for age
  // useful for year_to fields, where the form value is excluding
  // i.e. transactions To Age 65 won't include the year 65
  // it will instead stop at 64
  const offset = excluding ? 1 : 0;

  // visual indication of year
  const year = baseYear + ageDiff + offset;
  const classes = useStyles({ year });

  return (
    <TextField
      type="number"
      fieldProps={{
        parse: (v) => parseFloat(v) - ageDiff - offset,
        format: (v) => v + ageDiff + offset,
        ...fieldProps,
      }}
      className={`${className} ${classes.input}`}
      onWheel={({ target }) => (target as HTMLInputElement).blur()}
      {...props}
    />
  );
};
