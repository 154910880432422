import type { VFC } from 'react';
import { useIsSnapshotLoaded } from 'src/clients/snapshots/hooks';
import { TransactionListLive } from 'src/clients/transactions/TransactionListLive';
import { TransactionListSnapshots } from 'src/clients/transactions/TransactionListSnapshot';

export type TransactionListProps = { retirement?: boolean };

export const TransactionList: VFC<TransactionListProps> = ({
  retirement = false,
}) =>
  useIsSnapshotLoaded() ? (
    <TransactionListSnapshots {...{ retirement }} />
  ) : (
    <TransactionListLive {...{ retirement }} />
  );
