import { Container, makeStyles } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import type { FC } from 'react';
import type { GridData } from 'src/clients/tables/types';

const useStyles = (numRows = 0) =>
  makeStyles((theme) => ({
    datagrid: {
      '& .MuiDataGrid-colCell': {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      '& .MuiDataGrid-row': {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white',
        },
      },
      '& .MuiDataGrid-row.Mui-odd': {
        backgroundColor: 'whitesmoke',
        '&:hover': {
          backgroundColor: 'whitesmoke',
        },
      },
    },
    drawer: {
      '& .MuiDrawer-paper': {
        maxWidth: 400,
        padding: theme.spacing(3),
      },
    },
    container: {
      height: 40 + numRows * 29 + 15,
      maxHeight: '90vh',
    },
  }))();

export const TableBase: FC<GridData> = (gridData) => {
  const classes = useStyles(gridData.rows.length);

  return (
    <Container className={classes.container} maxWidth={false} disableGutters>
      <DataGrid
        {...gridData}
        rowHeight={40}
        density="compact"
        disableSelectionOnClick
        pageSize={100}
        hideFooter
        className={classes.datagrid}
      />
    </Container>
  );
};
