import type { ButtonProps } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import type { FC } from 'react';
import { useListButtonStyles } from 'src/components/buttons/DeleteButton';

export const LoadButton: FC<ButtonProps> = ({ children, ...props }) => (
  <Button
    {...props}
    color="primary"
    variant="contained"
    className={useListButtonStyles().tableButton}
    startIcon={<Replay />}
  >
    {children ?? 'Load'}
  </Button>
);
