import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { makeTitle } from 'src/clients/hooks/useClientPageTitle';
import type { ClientData } from 'src/clients/types';
import { addCashflowReportSheet } from 'src/export/addCashflowReportSheet';
import { addCashflowSheet } from 'src/export/addCashflowSheet';
import { addContributionsSheet } from 'src/export/addContributionsSheet';
import { addKiwisaverSheet } from 'src/export/addKiwisaverSheet';
import { addKiwisaverSideBySideSheet } from 'src/export/addKiwisaverSideBySideSheet';
import { addRetirementSheet } from 'src/export/addRetirementSheet';
import { addWithdrawalsSheet } from 'src/export/addWithdrawalsSheet';

export const makeXlsx = (clientData: ClientData) => {
  const workbook = new ExcelJS.Workbook();

  const names = makeTitle(clientData).replaceAll(' ', '-');
  const date = dayjs().format('YYYY-MM-DD');

  workbook.title = `${names || clientData.id}__${date}`;

  if (clientData.partner) {
    addKiwisaverSideBySideSheet(workbook, clientData);
    addKiwisaverSheet(workbook, clientData, 'main');
    addKiwisaverSheet(workbook, clientData, 'partner');
  } else {
    addKiwisaverSheet(workbook, clientData, 'main');
  }

  addContributionsSheet(workbook, clientData);
  addWithdrawalsSheet(workbook, clientData);
  addCashflowSheet(workbook, clientData);
  addCashflowReportSheet(workbook, clientData);
  addRetirementSheet(workbook, clientData);

  return workbook;
};
