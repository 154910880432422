import type { FC } from 'react';
import React from 'react';
import type { FilterProps } from 'react-admin';
import { Filter, SearchInput } from 'react-admin';

type CustomFilterProps = Omit<FilterProps, 'children'>;

export const ClientFilters: FC<CustomFilterProps> = (props) => (
  <Filter {...props}>
    <SearchInput key="search" source="name" alwaysOn placeholder="Search" />
    <SearchInput
      key="search.partner.name"
      source="partner.name"
      placeholder="Search Partner"
    />
  </Filter>
);
