import { getInitialAge } from 'src/calc/age/getInitialAge';
import { getContributions } from 'src/calc/transactions/getContributions';
import { getKiwiSaver } from 'src/calc/transactions/getKiwiSaver';
import { makeAgeGridData } from 'src/clients/tables/functions/makeAgeGridData';
import { makeGridColumns } from 'src/clients/tables/functions/makeGridColumns';
import { makeGridRows } from 'src/clients/tables/functions/makeGridRows';
import { makeTableDataFromEntry } from 'src/clients/tables/functions/makeTableDataFromEntry';
import { makeYearGridData } from 'src/clients/tables/functions/makeYearGridData';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';

export const makeContributionsGridData = (clientData: ClientData) => {
  // get contributions from clientData

  const {
    transactions: { combined: contributionsCombined },
    totals: {
      withKiwisaver: { cumulative, yearly },
    },
  } = getContributions(clientData);

  const kiwisaver = getKiwiSaver(clientData);

  const maxLength = cumulative.length;
  const { ageColumn, agesArray } = makeAgeGridData(maxLength);
  const { yearColumn } = makeYearGridData(clientData, agesArray);

  const kiwisaverColumn = {
    id: 'kiwisaver',
    title: 'KiwiSaver',
    array: kiwisaver.totals.total,
  };
  // shows age array as first column of the grid
  const totalColumn: TransactionTableData = {
    id: 'total',
    title: 'Year Total',
    array: yearly,
  };
  const cumulativeTotalColumn: TransactionTableData = {
    id: 'cumTotal',
    title: 'Cumulative Total',
    array: cumulative,
  };

  const allColumns: TransactionTableData[] = [
    yearColumn,
    ageColumn,
    ...contributionsCombined.map(makeTableDataFromEntry()),
    kiwisaverColumn,
    totalColumn,
    cumulativeTotalColumn,
  ];

  const initialAge = getInitialAge(clientData);
  const rows = makeGridRows(maxLength, allColumns, initialAge);
  const columns = makeGridColumns(allColumns, clientData);

  return { rows, columns, allColumns };
};
