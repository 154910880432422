//@ts-ignore
import Numbers from 'numbers';
import {
  evalDist,
  generateMaxDist,
  generateMinDist,
  getMaximumError,
  isErrorLower,
  zeroArray,
} from 'src/calc/returns/utils';
import type { ClientReturns } from 'src/clients/types';
import { defaultPortfolio } from 'src/globals/defaults';
import type { Portfolio } from 'src/globals/types';

export const MAX_ITERATIONS = 1000000;

type GenerateRandomReturns = {
  portfolio: Portfolio;
  startYear: number;
  endYear: number;
  maxIterations?: number;
  logging?: boolean;
  async?: boolean;
  reportCallback?: (i: number) => void | Promise<void>;
};

export const generateRandomReturns = async ({
  portfolio,
  startYear,
  endYear,
  async,
  reportCallback,
  maxIterations = MAX_ITERATIONS,
  logging,
}: GenerateRandomReturns): Promise<ClientReturns> => {
  const { year1, year20 } = portfolio || defaultPortfolio;

  const duration = endYear - startYear;

  // HELPERS
  // Generates an array of n uniformly distributed random numbers inside the interval
  const normal: (length: number, mu: number, sigma: number) => number[] =
    Numbers.random.distribution.normal;

  // max and min are each on the 95% percentile, i.e. 2sigma
  // therefore we can calculate sigma as:
  const sigma = (year1.max_return - year1.min_return) / 4;

  // error margins for use as stop criteria
  // const maxMinMargins = (year20.max_return - year20.min_return) / 20;
  const maxMinMargins = 0.005;
  // const avgMargin = year20.avg_return / 20;
  const avgMargin = 0.005;

  // INITIAL VALUES
  const candidate = zeroArray(duration);
  // const candidate = makeReturnsObject(distribution, startYear);

  // everyone starts from zero
  let avgDist = candidate;
  let minDist = candidate;
  let maxDist = candidate;

  let avgError = evalDist(candidate, portfolio, 'avg');
  let maxError = evalDist(candidate, portfolio, 'max');
  let minError = evalDist(candidate, portfolio, 'min');

  // loop control
  let iAvg = 0;
  let iMax = 0;
  let iMin = 0;
  //logging
  let k = 0;

  // find optimal avg distribution
  while (iAvg++ < maxIterations && !isErrorLower(avgError, avgMargin)) {
    // generate a new distribution
    const avgDistNew = normal(duration, year1.avg_return, sigma);
    // evaluate errors
    const avgErrorNew = evalDist(avgDistNew, portfolio, 'avg');
    // check if distribution is closer to the ideal for each scenario
    // if so we keep it, otherwise discard it and stick with the previous distribution
    if (getMaximumError(avgErrorNew) < getMaximumError(avgError)) {
      avgDist = avgDistNew;
      avgError = avgErrorNew;
      k++;
    }

    // release the event loop periodically to avoid blocking rendering
    if (async) {
      if (iAvg > 50000 && !(iAvg % 50000))
        await new Promise((resolve) =>
          setImmediate
            ? //faster but unstable api
              setImmediate(() => {
                reportCallback && reportCallback(iAvg);
                resolve(iAvg);
              })
            : // fallback for incompatible browsers
              setTimeout(resolve, 0)
        );
    }
  }
  // setTimeout(() => null, 0); //release the event loop

  logging && console.log(iAvg, 'avg', k);
  k = 0;

  //MAX
  // max will always be greater than avg over time
  while (iMax++ < maxIterations && !isErrorLower(maxError, maxMinMargins)) {
    const maxDistNew = generateMaxDist(avgDist, portfolio);
    const maxErrorNew = evalDist(maxDistNew, portfolio, 'max');

    if (getMaximumError(maxErrorNew) < getMaximumError(maxError)) {
      maxDist = maxDistNew;
      maxError = maxErrorNew;
      k++;
    }

    // release the event loop periodically to avoid blocking rendering
    if (async) {
      if (iMax > 50000 && !(iMax % 50000))
        await new Promise((resolve) =>
          setImmediate
            ? //faster but unstable api
              setImmediate(() => {
                reportCallback && reportCallback(maxIterations + iMax);
                resolve(maxIterations + iMax);
              })
            : // fallback for incompatible browsers
              setTimeout(resolve, 0)
        );
    }
  }
  logging && console.log(iMax, 'max', k);
  k = 0;

  // MIN
  // min will always be less than avg over time
  while (iMin++ < maxIterations && !isErrorLower(minError, maxMinMargins)) {
    const minDistNew = generateMinDist(avgDist, portfolio);
    const minErrorNew = evalDist(minDistNew, portfolio, 'min');

    if (getMaximumError(minErrorNew) < getMaximumError(minError)) {
      // minGrowth = makeReturnsObject(minDist, startYear);
      minDist = minDistNew;
      minError = minErrorNew;
      k++;
    }

    // release the event loop periodically to avoid blocking rendering
    if (async) {
      if (iMin > 50000 && !(iMin % 50000))
        await new Promise((resolve) =>
          setImmediate
            ? //faster but unstable api
              setImmediate(() => {
                reportCallback && reportCallback(2 * maxIterations + iMin);
                resolve(2 * maxIterations + iMin);
              })
            : // fallback for incompatible browsers
              setTimeout(resolve, 0)
        );
    }
  }
  logging && console.log(iMin, 'min', k);
  const solved =
    iAvg < maxIterations && iMax < maxIterations && iMin < maxIterations;

  const zeroPad = zeroArray(startYear);
  const avg = [...zeroPad, ...avgDist];
  const max = [...zeroPad, ...maxDist];
  const min = [...zeroPad, ...minDist];

  return { avg, max, min, solved };
};
