import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import {
  Checkboxes,
  Debug,
  makeValidateSync,
  Select,
  TextField,
} from 'mui-rff';
import type { FC } from 'react';
import { useRecordContext } from 'react-admin';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import {
  getInitialAge,
  getBirthYear,
  getYearAtAge,
  getYearAtPartnerAge,
} from 'src/calc/age/getInitialAge';
import { useUpdateArrayElements } from 'src/clients/hooks/useUpdateArrayElements';
import { AssetSelect } from 'src/clients/transactions/AssetSelect';
import {
  isRetirementBudget,
  isRetirementTransaction,
} from 'src/clients/transactions/functions/helpers';
import type { TransactionListProps } from 'src/clients/transactions/TransactionList';
import type { Transaction } from 'src/clients/transactions/types';
import { TRANSACTION_TYPES } from 'src/clients/transactions/types';
import type { Client } from 'src/clients/types';
import { ResetButton } from 'src/components/buttons/ResetButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { AgeField } from 'src/components/forms/AgeField';
import { useGlobals } from 'src/globals/hooks';
import { formatAsCurrency } from 'src/utils/renders';
import { getAgeAtEarliestRetirementYear } from 'src/calc/age/getRetirementAge';
import { getAgeDiff, getPartnerAgeAtYear } from 'src/calc/age/getClientAge';

export type DrawerFormProps = { onClose?: () => void };
export type TransactionFormProps = DrawerFormProps &
  TransactionListProps & {
    transaction: Transaction;
  };

export const TransactionForm: FC<TransactionFormProps> = ({
  transaction,
  retirement,
  onClose,
}) => {
  const record: Client = useRecordContext();

  const { upsertTransaction, response } = useUpdateArrayElements(record);

  const handleSubmit = (updatedTransaction: Transaction) => {
    upsertTransaction(updatedTransaction);
    onClose && onClose();
  };

  const classes = useDrawerFormStyles();

  const initialAge = getInitialAge(record);
  // const retirementAge = record.retirement_age;
  const retirementAge = getAgeAtEarliestRetirementYear(record);
  const validate = makeValidateSync(getSchema({ initialAge, retirementAge }));

  const { data: globals } = useGlobals();
  const { nz_super } = globals ?? {};

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={transaction}
      subscription={{ values: true, submitting: true, pristine: true }}
      render={({ handleSubmit, values, ...props }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <AssetSelect {...{ retirement }} />

          {values.asset === 'NZ Super' ? (
            <Select type="number" label="Value" name="value">
              {nz_super?.rates.map(({ condition, annual_rate }) => (
                <MenuItem value={annual_rate} key={condition}>
                  {condition} - {formatAsCurrency(annual_rate)}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              type="number"
              label="Value"
              name="value"
              fieldProps={{ parse: parseFloat }}
              onWheel={({ target }) => (target as HTMLInputElement).blur()}
            />
          )}

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <AgeField
                type="number"
                label={`${values.recurring ? 'From' : 'At'}
                ${values.asset?.endsWith('- Partner') ? 'Partner' : ''}
                Age`}
                name="year_from"
                baseYear={
                  values.asset?.endsWith('- Partner')
                    ? getYearAtPartnerAge(record, values.year_from ?? 0) ?? 0
                    : getYearAtAge(record, values.year_from ?? 0)
                }
                ageDiff={
                  values.asset?.endsWith('- Partner')
                    ? getAgeDiff(record)
                    : undefined
                }
              />
            </Grid>
            {values.recurring && (
              <Grid item xs={5}>
                <AgeField
                  type="number"
                  label={`To 
                  ${values.asset?.endsWith('- Partner') ? 'Partner' : ''} 
                  Age (excluding)`}
                  name="year_to"
                  disabled={!values.recurring}
                  baseYear={
                    values.asset?.endsWith('- Partner')
                      ? getYearAtPartnerAge(record, values.year_to ?? 0) ?? 0
                      : getYearAtAge(record, values.year_to ?? 0)
                  }
                  ageDiff={
                    values.asset?.endsWith('- Partner')
                      ? getAgeDiff(record)
                      : undefined
                  }
                  excluding
                />
              </Grid>
            )}
          </Grid>

          <Checkboxes
            name="recurring"
            data={{ label: 'Recurring?', value: false }}
            onClick={(e) =>
              (values.year_to = (e.target as HTMLInputElement).checked
                ? values.year_from
                : null)
            }
          />

          <Checkboxes
            name="inflation_adjusted"
            data={{ label: 'Inflation adjusted?', value: false }}
          />
          <SubmitButton disabled={props.pristine} />
          <ResetButton
            onClick={() => props.form.reset()}
            disabled={props.pristine}
          />
        </form>
      )}
    ></Form>
  );
};

type SchemaProps = { initialAge?: number; retirementAge?: number };
const getSchema = ({
  initialAge = 0,
  retirementAge = 0,
}: SchemaProps): Yup.SchemaOf<Partial<Transaction>> =>
  Yup.object({
    id: Yup.string().required(),
    title: Yup.string().required(),
    type: Yup.string()
      .required()
      .equals([...TRANSACTION_TYPES]),
    value: Yup.number().required().min(0),
    asset: Yup.string().required(),
    year_from: Yup.number()
      .required()
      .test({
        name: 'minAge',
        test: (value, { parent, createError }) => {
          const isRetirement = isRetirementTransaction(parent.type);
          const isBudget = isRetirementBudget(parent.type);

          // As requested on 14/04/2022
          // we now allow retiremen budget transactionst to start from the initial age
          const minAge = isRetirement && !isBudget ? retirementAge : initialAge;

          return (
            (value && value >= minAge) ||
            createError({
              message: `Must be greater than or equal to ${minAge}`,
              path: 'year_from',
            })
          );
        },
      }),
    year_to: Yup.number()
      .notRequired()
      .nullable()
      .min(Yup.ref<number>('year_from'), 'Must be greater than "From Age"'),
    recurring: Yup.boolean().required(),
    inflation_adjusted: Yup.boolean().required(),
  });

export const useDrawerFormStyles = makeStyles((theme) => ({
  form: {
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  popover: { pointerEvents: 'none' },
  paper: { padding: theme.spacing(1) },
}));
