//@ts-ignore
import Numbers from 'numbers';
import type {
  ClientReturns,
  Distribution,
  GrowhRatesArrays,
  Percentage,
  ReturnBands,
  ReturnsDataExpanded,
  ReturnsExpanded,
} from 'src/clients/types';
import type { Portfolio, ReturnEstimates } from 'src/globals/types';

export const makeReturnsExpanded = (
  returns: ClientReturns,
  startYear = 0
): ReturnsExpanded => ({
  avg: makeReturnsDataExpanded(returns.avg, startYear),
  max: makeReturnsDataExpanded(returns.max, startYear),
  min: makeReturnsDataExpanded(returns.min, startYear),
});

const makeReturnsDataExpanded = (
  distribution: number[],
  startYear = 0
): ReturnsDataExpanded => ({
  distribution,
  averageRates: growthRatesArrays(distribution, startYear),
});

export const zeroPadLeft = (array: number[], paddingLength = 0) => [
  ...zeroArray(paddingLength),
  ...array,
];

// https://en.wikipedia.org/wiki/Geometric_mean#Average_growth_rate
export const growthRatesArrays = (
  distribution: Distribution,
  startYear = 0
): GrowhRatesArrays => {
  const validDistribution = distribution.slice(startYear);

  const cumulative: Percentage[] = [];
  const yoy: Percentage[] = [];

  for (const [year, roi] of Array.from(validDistribution.entries())) {
    cumulative[year] =
      year === 0 ? roi : compoundRoi(cumulative[year - 1], roi);

    yoy[year] = year === 0 ? roi : getYoyRate(cumulative[year], year + 1);
  }

  return {
    cumulative: zeroPadLeft(cumulative, startYear),
    yoy: zeroPadLeft(yoy, startYear),
  };
};

// export const averageGrowthRate = (distribution: number[]): number =>
//   distribution.reduce((acc, n) => acc * (1 + n), 1) **
//     (1 / (distribution.length || 1)) -
//   1;

export const compoundRoi = (currentValue: number, nextValue: number) =>
  (1 + currentValue) * (1 + nextValue) - 1;

export const getYoyRate = (cumulativeValue: number, duration: number) =>
  (1 + cumulativeValue) ** (1 / duration) - 1;

const arrayMean = (arr1: number[], arr2: number[], i: number) =>
  arr1.map((n1, k) => (n1 * (i + 1) + arr2[k]) / i + 2);

export const randomBounded = (min: number, max: number): number =>
  min + (max - min) * Math.random();

export const zeroArray = (length: number): number[] =>
  new Array(length).fill(0);

export const randomBoundedArray = (min: number, max: number, length: number) =>
  zeroArray(length).map(() => randomBounded(min, max));

const mixDistributions = (d1: number[], d2: number[], padding: number) => {
  const distribution = d1.map((value, i) => (value + d2[i]) / 2);
  const averageRate = growthRatesArrays(distribution);

  const zeroPad = new Array(padding).fill(0);
  return { distribution, averageRate };
};

type MilestoneErrors = typeof zeroError;
const zeroError = {
  y1: 0,
  y5: 0,
  y10: 0,
  y20: 0,
  y30: 0,
  y40: 0,
  y50: 0,
  end: 0,
};

/**
 * Calculates long term estimate errors
 */
export const evalDist = (
  dist: number[],
  portfolio: Portfolio,
  band: ReturnBands
) => {
  const { year1, year5, year10, year20 } = portfolio;
  const duration = dist.length;
  const error = { ...zeroError };
  const key: keyof ReturnEstimates = `${band}_return`;

  const { yoy } = growthRatesArrays(dist);
  const getGrowthRate = (y: number) => {
    const end = Math.min(duration, y) - 1;
    return yoy[end];
  };

  const rate = getGrowthRate(1);
  error.y1 = Math.abs(rate - year1[key]);

  if (duration > 2) {
    const rate = getGrowthRate(5);
    error.y5 = Math.abs(rate - year5[key]);
  }
  if (duration > 7) {
    const rate = getGrowthRate(10);
    error.y10 = Math.abs(rate - year10[key]);
  }
  if (duration > 15) {
    const rate = getGrowthRate(20);
    error.y20 = Math.abs(rate - year20[key]);
  }
  if (duration > 25) {
    const rate = getGrowthRate(30);
    error.y30 = Math.abs(rate - year20[key]);
  }
  if (duration > 35) {
    const rate = getGrowthRate(40);
    error.y40 = Math.abs(rate - year20[key]);
  }
  if (duration > 45) {
    const rate = getGrowthRate(50);
    error.y50 = Math.abs(rate - year20[key]);
  }
  if (duration > 50) {
    const rate = getGrowthRate(duration);
    error.end = Math.abs(rate - year20[key]);
  }

  return error;
};

/**
 * Returns highest absolute error; longer term errors are given more weight
 */
export const getMaximumError = (error: MilestoneErrors) =>
  Object.values(error).reduce(
    (acc, e, y) => Math.max(acc, Math.abs(e) * (y + 1)),
    // (acc, e, y) => Math.max(acc, Math.abs(e)),
    0
  );

export const isErrorLower = (error: MilestoneErrors, threshold: number) =>
  getMaximumError(error) <= threshold;

export const clipBottom = (array: number[], min: number[]) =>
  array.map((n, y) => (n > min[y] ? n : min[y]));

export const clipTop = (array: number[], max: number[]) =>
  array.map((n, y) => (n < max[y] ? n : max[y]));

/**
 * Based on the already found average distribution, generate the maximum (best case scenario) distribution.
 *
 * This method proved very accurate! It was apparent that with the previous methods of dumb clipping, differences between max/avg/min distributions were always increasing, resulting in innacurate long term simulations. The initial years specfically are crucial, since once the max/min distributions grow apart from the avg, they could never compensate that later (because there can never be a year where max < avg...). By taking into account the longer term estimates for year5/10/20, we greatly dampen the gap that can be formed between scenarios.
 *
 * The rules are:
 *
 * 1. The max value must always be >= the avg value
 * 2. The avg value is compared against the estimated max_return for the current year, based on the portfolio short/long terms estimates
 * 3. If the avg is >= the max estimated, it's an outlier, so we use it as is
 * 4. Otherwise, pick a random value between avg and max estimated
 *
 * @param avgDist The Average distribution, previously found by simulating.
 * @param portfolio The client's portfolio
 * @returns A distribution that fulfills all the criteria above
 */
export const generateMaxDist = (
  avgDist: number[],
  { year1, year5, year10, year20 }: Portfolio
): number[] =>
  avgDist.map((avg, year) => {
    const timeframe =
      year < 2 ? year1 : year < 7 ? year5 : year < 15 ? year10 : year20;
    const maxValue = timeframe.max_return;

    return avg >= maxValue ? avg : Numbers.random.sample(avg, maxValue, 1)[0];
  });

// see comments above, this is the equivalent for the minimum returns
export const generateMinDist = (
  avgDist: number[],
  { year1, year5, year10, year20 }: Portfolio
): number[] =>
  avgDist.map((avg, year) => {
    const timeframe =
      year < 2 ? year1 : year < 7 ? year5 : year < 15 ? year10 : year20;
    const minValue = timeframe.min_return;

    return avg <= minValue ? avg : Numbers.random.sample(minValue, avg, 1)[0];
  });
