import type {
  RetirementTransactionType,
  TransactionType,
} from 'src/clients/transactions/types';
import { RETIREMENT_TRANSACTION_TYPES } from 'src/clients/transactions/types';

export const isRetirementTransaction = (
  type: TransactionType
): type is RetirementTransactionType =>
  RETIREMENT_TRANSACTION_TYPES.includes(type as RetirementTransactionType);

export const isRetirementBudget = (type: TransactionType): boolean =>
  (type as RetirementTransactionType) === 'Retirement Budget';
