import type { VFC } from 'react';
import { useSnapshotOrRecordContext } from 'src/clients/snapshots/hooks';
import { makeContributionsGridData } from 'src/clients/tables/functions/makeContributionsGridData';
import { TableBase } from 'src/clients/tables/TableBase';

export const TableContributions: VFC = () => {
  const { clientData } = useSnapshotOrRecordContext();
  if (!clientData) return null;

  const gridData = makeContributionsGridData(clientData);

  return <TableBase {...gridData} />;
};
