import { BooleanInput } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { PercentInput } from 'src/components/forms/PercentInput';
import { useGlobals } from 'src/globals/hooks';
import { sampleGlobals } from 'src/utils/testData';

export const InflationForm: React.FC = () => {
  const { values } = useFormState();
  const { change } = useForm();
  const { data: globals = sampleGlobals } = useGlobals();

  const setInflation = (checked?: boolean) => {
    checked && globals && change('inflation_rate', globals?.inflation_rate);
  };

  return (
    <>
      <BooleanInput source="use_global_inflation" onChange={setInflation} />
      <PercentInput
        disabled={values?.use_global_inflation}
        source="inflation_rate"
        label="Inflation Rate (%)"
      />
    </>
  );
};
