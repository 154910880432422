import { getInitialAge } from 'src/calc/age/getInitialAge';
import { getCashflow } from 'src/calc/transactions/getCashflow';
import { getRetirementIncome } from 'src/calc/transactions/getRetirementIncome';
import { makeAgeGridData } from 'src/clients/tables/functions/makeAgeGridData';
import { makeGridColumns } from 'src/clients/tables/functions/makeGridColumns';
import { makeGridRows } from 'src/clients/tables/functions/makeGridRows';
import { makeYearGridData } from 'src/clients/tables/functions/makeYearGridData';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';

export const makeCashflowGridData = (clientData: ClientData) => {
  // get contributions from clientData
  const {
    contributions,
    withdrawals,
    net,
    returns: {
      avg: { roiAmount, roiPercent, cumulative },
    },
  } = getCashflow(clientData);

  const {
    totals: { yearlyIncome: retirementIncome, shortfall, budget },
  } = getRetirementIncome(clientData);

  // shows age array as first column of the grid

  const { ageColumn, agesArray } = makeAgeGridData(cumulative.length);
  const { yearColumn } = makeYearGridData(clientData, agesArray);

  const totalContributionsColumn: TransactionTableData = {
    id: 'totalContr',
    title: 'Total Contributions',
    array: contributions,
  };
  const totalWithdrawalsColumn: TransactionTableData = {
    id: 'totalWith',
    title: 'Total Withdrawn',
    array: withdrawals,
  };
  const retirementIncomeColumn: TransactionTableData = {
    id: 'totalRetirement',
    title: 'Income During Retirement',
    array: retirementIncome,
  };
  const retirementBudgetColumn: TransactionTableData = {
    id: 'retirementBudget',
    title: 'Retirement Budget',
    array: budget,
  };
  const shortfallColumn: TransactionTableData = {
    id: 'shortfall',
    title: 'Retirement Shortfall',
    array: shortfall,
  };
  const totalNetColumn: TransactionTableData = {
    id: 'total',
    title: 'Year Net',
    array: net,
  };
  const roiAmountColumn: TransactionTableData = {
    id: 'roiAmount',
    title: 'Return on Investment',
    array: roiAmount,
  };
  const roiPercentColumn: TransactionTableData = {
    id: 'roiPercent',
    title: 'Yield',
    array: roiPercent,
  };
  const cumulativeTotalColumn: TransactionTableData = {
    id: 'cumTotal',
    title: 'Cumulative Total',
    array: cumulative,
  };

  const allColumns: TransactionTableData[] = [
    yearColumn,
    ageColumn,
    totalContributionsColumn,
    totalWithdrawalsColumn,
    retirementBudgetColumn,
    retirementIncomeColumn,
    shortfallColumn,
    totalNetColumn,
    roiAmountColumn,
    roiPercentColumn,
    cumulativeTotalColumn,
  ];

  const initialAge = getInitialAge(clientData);

  const rows = makeGridRows(cumulative.length, allColumns, initialAge);
  const columns = makeGridColumns(allColumns, clientData);
  // const columns: GridColumns = allColumns.map(({ id, title }) => ({
  //   field: id,
  //   headerName: title,
  //   sortable: false,
  //   width: isCurrencyColumn(id) ? 200 : 100,
  //   valueFormatter:
  //     id === 'roiPercent'
  //       ? roiPercentFormatter
  //       : isCurrencyColumn(id)
  //       ? gridCurrencyFormatter
  //       : undefined,
  // }));

  return { rows, columns, allColumns };
};
