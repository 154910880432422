import { Button } from '@material-ui/core';
import { TextField } from 'mui-rff';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import type firebase from 'firebase/compat/app';
import { authProvider } from 'src/config/react-admin-firebase-config';
import type { User } from 'src/users/types';

export const UserShow: FC = () => {
  const [user, setUser] = useState<firebase.User>();

  // get user info from current session
  useEffect(() => {
    const init = async () => {
      try {
        const current = (await authProvider.checkAuth(
          {}
        )) as unknown as firebase.User;
        setUser(current);
      } catch (e) {
        console.error(e);
      }
    };
    init();
  }, []);

  // [Manage Users in Firebase](https://firebase.google.com/docs/auth/web/manage-users#get_a_users_profile)
  const update = ({ displayName }: User) => {
    user
      ?.updateProfile({
        displayName,
        photoURL: 'https://example.com/jane-q-user/profile.jpg',
      })
      .then(console.log)
      .catch(console.error);
  };

  // #TODO [react-admin-firebase/CustomForgotPassword.js at master · benwinding/react-admin-firebase](https://github.com/benwinding/react-admin-firebase/blob/master/src-demo/src/CustomForgotPassword.js)

  if (!user) return null;

  return (
    <Form
      initialValues={user}
      onSubmit={update}
      render={({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextField label="Name" name="displayName" />
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </form>
      )}
    />
  );
};
