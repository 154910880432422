import { Checkbox } from '@material-ui/core';
import type { GridColDef } from '@material-ui/data-grid';
import { getAgeDiff } from 'src/calc/age/getClientAge';
import { useSnapshotOrRecordContext } from 'src/clients/snapshots/hooks';

export const AgeCell: GridColDef['renderCell'] = ({ getValue }) => {
  const { clientData } = useSnapshotOrRecordContext();
  const isPartner = getValue('partner') as boolean;

  const ageDiff = isPartner ? getAgeDiff(clientData) : 0;

  const yearFrom = (getValue('year_from') as number) + ageDiff;
  const yearTo = (getValue('year_to') as number) + ageDiff;
  return (
    <>
      {yearFrom}
      {/* yearTo gets +1 added because the data point works
       *excluding*, i.e. if year_to is 64 the calculations consider that whole year up to age 65. See also AgeField */}
      {!!yearTo ? ` to ${yearTo + 1}` : null}
    </>
  );
};

export const BooleanCell: GridColDef['renderCell'] = ({ value }) => (
  <Checkbox checked={!!value} disabled />
);

export const HiddenBooleanCell: GridColDef['renderCell'] = ({ value }) => (
  <>{!!value ? <Checkbox checked={true} disabled /> : null}</>
);
