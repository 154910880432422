import { getInitialAge } from 'src/calc/age/getInitialAge';
import type { MainOrPartnerOrBoth } from 'src/calc/transactions/getIncome';
import { getIncome } from 'src/calc/transactions/getIncome';
import { makeAgeGridData } from 'src/clients/tables/functions/makeAgeGridData';
import { makeGridColumns } from 'src/clients/tables/functions/makeGridColumns';
import { makeGridRows } from 'src/clients/tables/functions/makeGridRows';
import { makeTableDataFromEntry } from 'src/clients/tables/functions/makeTableDataFromEntry';
import { makeYearGridData } from 'src/clients/tables/functions/makeYearGridData';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';

export const makeIncomeGridData = (
  clientData: ClientData,
  client: MainOrPartnerOrBoth
) => {
  const income = getIncome(clientData);

  // the calculations iterate on the length of the longest transaction (greatest year_from or year_to)
  const maxLength = income.totals.totals.length;

  // shows age array as first column of the grid
  const { ageColumn, agesArray } = makeAgeGridData(maxLength);
  const { yearColumn } = makeYearGridData(clientData, agesArray);

  const incomeData = client ? income[client] : income.totals;
  const { totals, cumulative, combined } = incomeData;

  // table columns data populated based on client/partner selected
  const totalColumn: TransactionTableData = {
    id: 'total',
    title: 'Year Total',
    array: totals,
  };
  const cumulativeTotalColumn: TransactionTableData = {
    id: 'cumTotal',
    title: 'Cumulative Total',
    array: cumulative,
  };

  const allColumns: TransactionTableData[] = [
    yearColumn,
    ageColumn,
    ...combined.map(makeTableDataFromEntry('title')),
    totalColumn,
    cumulativeTotalColumn,
  ];

  const initialAge = getInitialAge(clientData);
  const rows = makeGridRows(maxLength, allColumns, initialAge);
  const columns = makeGridColumns(allColumns, clientData);

  return { rows, columns, allColumns };
};
