import type ExcelJS from 'exceljs';
import { getInitialAge } from 'src/calc/age/getInitialAge';
import type { MainOrPartnerOrBoth } from 'src/calc/transactions/getIncome';
import { makeKiwisaverGridData } from 'src/clients/tables/functions/makeKiwisaverGridData';
import type { ClientData } from 'src/clients/types';
import { tableDataToSheetColumn } from 'src/export/tableDataToSheetColumn';
import { tableDataToSheetRows } from 'src/export/tableDataToSheetRows';

export const addKiwisaverSheet = (
  workbook: ExcelJS.Workbook,
  clientData: ClientData,
  client?: MainOrPartnerOrBoth
) => {
  const worksheet = workbook.addWorksheet(
    `Kiwisaver${client ? `-${client}` : ''}`
  );

  // track on which row we are
  let rowCursor = 1;

  const { allColumnsArray: allColumns } = makeKiwisaverGridData(
    clientData,
    client
  );

  // define sheet columns, each column has an id, later to be used for populating the values
  worksheet.columns = allColumns.map(tableDataToSheetColumn);

  // top Row
  worksheet.getRow(rowCursor).values = ['', 'KiwiSaver'];
  worksheet.mergeCells('B1:G1');
  worksheet.getRow(rowCursor).alignment = { horizontal: 'center' };
  worksheet.getRow(rowCursor).font = { bold: true };

  rowCursor = 2;

  // headers
  worksheet.getRow(rowCursor).values = allColumns.map(({ title }) => title);
  worksheet.getRow(rowCursor).alignment = { horizontal: 'center' };
  worksheet.getRow(rowCursor).font = { bold: true };
  worksheet.getRow(rowCursor).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F0F0F0' },
  };

  // POPULATE ROWS
  // for each age... (allColumns[0].array == ages)
  const initialAge = getInitialAge(clientData);
  rowCursor = tableDataToSheetRows({
    allColumns,
    worksheet,
    rowCursor,
    initialAge,
  });

  return rowCursor;
};
