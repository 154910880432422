import type ExcelJS from 'exceljs';
import { getInitialAge } from 'src/calc/age/getInitialAge';
import { makeCashflowGridData } from 'src/clients/tables/functions/makeCashflowGridData';
import type { ClientData } from 'src/clients/types';
import { tableDataToSheetColumn } from 'src/export/tableDataToSheetColumn';
import { tableDataToSheetRows } from 'src/export/tableDataToSheetRows';

export const addCashflowSheet = (
  workbook: ExcelJS.Workbook,
  clientData: ClientData
) => {
  const worksheet = workbook.addWorksheet('Cashflow');

  // track on which row we are
  let rowCursor = 1;

  const { allColumns } = makeCashflowGridData(clientData);

  // define sheet columns, each column has an id, later to be used for populating the values
  worksheet.columns = allColumns.map(tableDataToSheetColumn);

  // top Row
  worksheet.getRow(rowCursor).values = ['', 'Cashflow'];
  worksheet.mergeCells('B1:G1');
  worksheet.getRow(rowCursor).alignment = { horizontal: 'center' };
  worksheet.getRow(rowCursor).font = { bold: true };

  rowCursor = 2;

  // headers
  worksheet.getRow(rowCursor).values = allColumns.map(({ title }) => title);
  worksheet.getRow(rowCursor).alignment = { horizontal: 'center' };
  worksheet.getRow(rowCursor).font = { bold: true };
  worksheet.getRow(rowCursor).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F0F0F0' },
  };

  // POPULATE ROWS
  // for each age... (allColumns[0].array == ages)
  const initialAge = getInitialAge(clientData);

  rowCursor = tableDataToSheetRows({
    allColumns,
    worksheet,
    rowCursor,
    initialAge,
    formatAsPercentages: [10],
  });

  return rowCursor;
};
