import dayjs from 'dayjs';
import { Debug, makeValidateSync, TextField } from 'mui-rff';
import type { FC } from 'react';
import { useRecordContext } from 'react-admin';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { useUpdateArrayElements } from 'src/clients/hooks/useUpdateArrayElements';
import type { DrawerFormProps } from 'src/clients/transactions/TransactionForm';
import { useDrawerFormStyles } from 'src/clients/transactions/TransactionForm';
import type { Client } from 'src/clients/types';
import { ResetButton } from 'src/components/buttons/ResetButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import type { Snapshot } from 'src/snapshots/types';

export type SnapshotFormProps = DrawerFormProps & { snapshot: Snapshot };

export const SnapshotForm: FC<SnapshotFormProps> = ({ snapshot, onClose }) => {
  const record: Client = useRecordContext();

  // SAVE SNAPSHOT
  const { upsertSnapshot } = useUpdateArrayElements(record);

  const handleSubmit = (updatedSnapshot: Snapshot) => {
    upsertSnapshot(updatedSnapshot);
    onClose && onClose();
  };

  const classes = useDrawerFormStyles();
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={snapshot}
      subscription={{ values: true, submitting: true, pristine: true }}
      render={({ handleSubmit, values, ...props }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <TextField type="text" label="Title" name="title" />
          <TextField
            type="text"
            // label="Date"
            name="date"
            fieldProps={{ format: snapshotDateFormatter }}
            disabled
          />

          {/* <FormGroup> */}
          <SubmitButton />
          <ResetButton
            onClick={() => props.form.reset()}
            disabled={props.pristine}
          />
        </form>
      )}
    ></Form>
  );
};

const schema: Yup.SchemaOf<Partial<Snapshot>> = Yup.object({
  id: Yup.string().required(),
  title: Yup.string().required(),
  date: Yup.date().required(),
  data: Yup.mixed().required(),
});
const validate = makeValidateSync(schema);

export const snapshotDateFormatter = (value: string) =>
  dayjs(value).format('ddd DD/MM/YYYY hh:mm a');
