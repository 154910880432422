import { getInitialAge } from 'src/calc/age/getInitialAge';
import { findMaxEntryLength } from 'src/calc/transactions/findMaxEntryLength';
import { getWithdrawals } from 'src/calc/transactions/getWithdrawals';
import { makeAgeGridData } from 'src/clients/tables/functions/makeAgeGridData';
import { makeGridColumns } from 'src/clients/tables/functions/makeGridColumns';
import { makeGridRows } from 'src/clients/tables/functions/makeGridRows';
import { makeTableDataFromEntry } from 'src/clients/tables/functions/makeTableDataFromEntry';
import { makeYearGridData } from 'src/clients/tables/functions/makeYearGridData';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';

export const makeWithdrawalsGridData = (clientData: ClientData) => {
  // get contributions from clientData
  const {
    transactions: { combined: withdrawalsCombined },
    totals: { cumulative, yearly },
  } = getWithdrawals(clientData);

  // the calculations iterate on the length of the longest transaction (greatest year_from or year_to)
  const maxLength = findMaxEntryLength(withdrawalsCombined);

  // shows age array as first column of the grid
  const { ageColumn, agesArray } = makeAgeGridData(maxLength);
  const { yearColumn } = makeYearGridData(clientData, agesArray);

  const totalColumn: TransactionTableData = {
    id: 'total',
    title: 'Year Total',
    array: yearly,
  };

  const cumTotalColumn: TransactionTableData = {
    id: 'cumTotal',
    title: 'Cumulative Total',
    array: cumulative,
  };

  const allColumns: TransactionTableData[] = [
    yearColumn,
    ageColumn,
    ...withdrawalsCombined.map(makeTableDataFromEntry()),
    totalColumn,
    cumTotalColumn,
  ];

  const initialAge = getInitialAge(clientData);
  const rows = makeGridRows(maxLength, allColumns, initialAge);
  const columns = makeGridColumns(allColumns, clientData);

  return { rows, columns, allColumns };
};
