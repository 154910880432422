import { DataGrid } from '@material-ui/data-grid';
import type { VFC } from 'react';
import { useInputDataGridStyles } from 'src/clients/assets/AssetListLive';
import { makeIncomeColumns } from 'src/clients/income/functions/makeIncomeColumns';
import { useLoadedSnapshotData } from 'src/clients/snapshots/hooks';

export const IncomeListSnapshot: VFC = () => {
  const classes = useInputDataGridStyles();
  const clientData = useLoadedSnapshotData();
  if (!clientData) return null;
  const { incomeEntries = [] } = clientData;
  return (
    <div>
      <DataGrid
        columns={makeIncomeColumns(clientData)}
        rows={incomeEntries}
        rowHeight={40}
        autoHeight
        density="compact"
        disableSelectionOnClick
        className={classes.datagrid}
        sortModel={[
          { field: 'partner', sort: 'asc' },
          { field: 'age', sort: 'asc' },
        ]}
      />
    </div>
  );
};
