import type { GridRowsProp } from '@material-ui/data-grid';
import type { TransactionTableData } from 'src/clients/tables/types';

// returns rows formatted for datagrid
export const makeGridRows = (
  maxLength: number,
  allColumns: TransactionTableData[],
  initialAge = 0
): GridRowsProp => {
  const actuaLentgh = Math.max(0, maxLength - initialAge);

  const emptyArray = new Array(actuaLentgh).fill({});

  const makeRow = (age: number) =>
    allColumns.reduce((obj, { id, array }) => ({ ...obj, [id]: array[age] }), {
      id: age,
    });

  return emptyArray.map((_, i) => makeRow(i + initialAge));
};
