import type { ButtonProps } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Undo } from '@material-ui/icons';
import type { VFC } from 'react';

export const ResetButton: VFC<ButtonProps> = (props) => (
  <Button {...props} variant="outlined" startIcon={<Undo />}>
    Reset
  </Button>
);
