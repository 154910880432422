import { makeStyles, MenuItem, Typography } from '@material-ui/core';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { Autocomplete, Select, TextField } from 'mui-rff';
import type { VFC } from 'react';
import { useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import { useForm } from 'react-final-form';
import { getTransactionAssets } from 'src/calc/transactions/getTransactionAssets';
import { isRetirementTransaction } from 'src/clients/transactions/functions/helpers';
import type { TransactionListProps } from 'src/clients/transactions/TransactionList';
import type { Transaction } from 'src/clients/transactions/types';
import {
  GENERAL_TRANSACTION_TYPES,
  RETIREMENT_TRANSACTION_TYPES,
} from 'src/clients/transactions/types';
import type { Client } from 'src/clients/types';

export const AssetSelect: VFC<TransactionListProps> = ({ retirement }) => {
  const record: Client = useRecordContext();
  const classes = useStyles();
  const { change, getFieldState } = useForm<Transaction>();

  const type = getFieldState('type')?.value;
  const asset = getFieldState('asset')?.value;

  const isRetirementBudget = type === 'Retirement Budget';
  const isRetirement = type && isRetirementTransaction(type);

  // Auto update asset for Retirement Transactions
  useEffect(() => {
    if (isRetirementBudget) {
      change('asset', 'Retirement Budget');
      change('title', 'Retirement Budget');
    } else if (asset === 'Retirement Budget') {
      // cleanup when unselecting Ret.Budget
      change('asset', undefined);
    }
  }, [asset, isRetirementBudget]);

  // Auto update title for Retirement Transactions
  useEffect(() => {
    if (isRetirement && asset) change('title', asset);
  }, [isRetirement, asset]);

  const options = getTransactionAssets(record).filter(
    (asset) => asset !== 'Retirement Budget'
  );

  const types = retirement
    ? RETIREMENT_TRANSACTION_TYPES
    : GENERAL_TRANSACTION_TYPES;

  return (
    <>
      <Select type="text" label="Transaction Type" name="type">
        {types.map((type) => (
          <MenuItem value={type} key={type}>
            {type}
          </MenuItem>
        ))}
      </Select>

      {!isRetirementBudget && (
        <Autocomplete
          label={
            <>
              {'Asset Type '}
              <PopupState variant="popover" popupId="demoPopover">
                {(popupState) => (
                  <>
                    <span {...bindHover(popupState)}>(READ THIS FIRST)</span>
                    <HoverPopover
                      {...bindPopover(popupState)}
                      className={classes.popover}
                      classes={{ paper: classes.paper }}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <Typography>
                        If you want to add a new value not on the list:
                        <ol>
                          <li>First type the value;</li>
                          <li>Then press ENTER;</li>
                          <li>You should see the new value on the list.</li>
                        </ol>
                      </Typography>
                    </HoverPopover>
                  </>
                )}
              </PopupState>
            </>
          }
          name="asset"
          freeSolo
          options={options}
        />
      )}
      {!isRetirement && <TextField type="text" label="Title" name="title" />}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiFormControl-root': {
      // maxWidth: 400,
      // padding: '0px 10px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  popover: { pointerEvents: 'none' },
  paper: { padding: theme.spacing(1) },
}));
