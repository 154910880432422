import type { FC } from 'react';
import type { InputProps } from 'react-admin';
import { NumberInput } from 'react-admin';

const parsePercent = (n: string) => parseFloat(n) / 100;
const formatPercent = (n: number) => Math.round(n * 10000) / 100;

export const PercentInput: FC<InputProps> = (props) => (
  <NumberInput
    parse={parsePercent}
    format={formatPercent}
    {...props}
    onWheel={({ target }) => (target as HTMLInputElement).blur()}
  />
);
