import { FormGroup, Grid, MenuItem } from '@material-ui/core';
import {
  Checkboxes,
  Debug,
  makeValidateSync,
  Select,
  TextField,
} from 'mui-rff';
import type { FC } from 'react';
import { Error, useRecordContext } from 'react-admin';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { getAgeDiff } from 'src/calc/age/getClientAge';
import {
  getInitialAge,
  getPartnerInitialAge,
  getYearAtAge,
  getYearAtPartnerAge,
} from 'src/calc/age/getInitialAge';
import { useUpdateArrayElements } from 'src/clients/hooks/useUpdateArrayElements';
import type { IncomeEntry } from 'src/clients/income/types';
import type { DrawerFormProps } from 'src/clients/transactions/TransactionForm';
import { useDrawerFormStyles } from 'src/clients/transactions/TransactionForm';
import type { Client } from 'src/clients/types';
import { ResetButton } from 'src/components/buttons/ResetButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { AgeField } from 'src/components/forms/AgeField';
import { useGlobals } from 'src/globals/hooks';

export type IncomeFormProps = DrawerFormProps & {
  transaction: IncomeEntry;
};

export const IncomeForm: FC<IncomeFormProps> = ({ transaction, onClose }) => {
  const record: Client = useRecordContext();
  const { partner } = record;

  const { upsertIncomeEntry, response } = useUpdateArrayElements(record);

  const classes = useDrawerFormStyles();

  const { data: globals, loading, error } = useGlobals();
  if (error) return <Error error="Error Loading Globals" />;

  const handleSubmit = (updatedIncomeEntry: IncomeEntry) => {
    upsertIncomeEntry(updatedIncomeEntry);
    onClose && onClose();
  };

  const validate = (values: IncomeEntry) => {
    const initialAge = values.partner
      ? getPartnerInitialAge(record)
      : getInitialAge(record);
    const ageDiff = values.partner ? getAgeDiff(record) : 0;
    return makeValidateSync(getSchema({ initialAge, ageDiff }))(values);
  };
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={transaction}
      subscription={{ values: true, submitting: true, pristine: true }}
      render={({ handleSubmit, values, ...props }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          {partner && (
            <Select
              type="text"
              label="Client"
              name="partner"
              fieldProps={{
                parse: (value) => value === 'partner',
                format: (value) => (value ? 'partner' : 'main'),
              }}
            >
              <MenuItem value={'main'}>{record.name}</MenuItem>
              <MenuItem value={'partner'}>{record.partner?.name}</MenuItem>
            </Select>
          )}

          <TextField type="text" label="Title" name="title" />
          <TextField
            type="number"
            label="Value"
            name="value"
            fieldProps={{ parse: parseFloat }}
            onWheel={({ target }) => (target as HTMLInputElement).blur()}
          />
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <AgeField
                label={`${values.recurring ? 'From' : 'At'} Age`}
                name="year_from"
                baseYear={
                  values.partner
                    ? getYearAtPartnerAge(record, values.year_from ?? 0) ?? 0
                    : getYearAtAge(record, values.year_from ?? 0)
                }
                ageDiff={values.partner ? getAgeDiff(record) : undefined}
              />
            </Grid>
            {values.recurring && (
              <Grid item xs={5}>
                <AgeField
                  label="To Age (excluding)"
                  name="year_to"
                  disabled={!values.recurring}
                  baseYear={
                    values.partner
                      ? getYearAtPartnerAge(record, values.year_to ?? 0) ?? 0
                      : getYearAtAge(record, values.year_to ?? 0)
                  }
                  ageDiff={values.partner ? getAgeDiff(record) : undefined}
                  excluding
                />
              </Grid>
            )}
          </Grid>

          <Checkboxes
            name="recurring"
            data={{ label: 'Recurring?', value: false }}
            onClick={(e) =>
              (values.year_to = (e.target as HTMLInputElement).checked
                ? values.year_from
                : null)
            }
          />
          <Checkboxes
            // label="Inflation"
            name="inflation_adjusted"
            data={{ label: 'Inflation adjusted?', value: false }}
          />
          <FormGroup>
            {globals && (
              <Select type="text" label="KiwiSaver %" name="kiwisaver">
                {globals.kiwisaver.rates.map(({ contribution }) => (
                  <MenuItem value={contribution} key={contribution}>
                    {contribution * 100}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormGroup>
          <SubmitButton disabled={props.pristine} />
          <ResetButton
            onClick={() => props.form.reset()}
            disabled={props.pristine}
          />
        </form>
      )}
    ></Form>
  );
};

type SchemaProps = {
  initialAge?: number;
  ageDiff?: number;
};

const getSchema = ({
  initialAge = 0,
  ageDiff = 0,
}: SchemaProps): Yup.SchemaOf<IncomeEntry> =>
  Yup.object({
    id: Yup.string().required(),
    title: Yup.string().required(),
    type: Yup.string().required().equals(['Income']),
    value: Yup.number().required().min(0),
    kiwisaver: Yup.number().required(),
    asset: Yup.string().required().equals(['Salary']),
    year_from: Yup.number()
      .required()
      .min(
        initialAge - ageDiff,
        // `Must be greater than or equal to Initial Age (${initialAge + ageDiff})`
        `Must be greater than or equal to Initial Age (${initialAge})`
      ),
    year_to: Yup.number()
      .notRequired()
      .nullable()
      .min(Yup.ref<number>('year_from'), 'Must be greater than "From Age"'),
    recurring: Yup.boolean().required(),
    inflation_adjusted: Yup.boolean().required(),
    partner: Yup.boolean(),
  });
