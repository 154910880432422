import type { ButtonProps } from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import type { VFC } from 'react';

const useStyles = makeStyles({
  submitButton: {
    marginTop: '1rem',
    marginBottom: '1rem',
    marginRight: '0.5rem',
  },
});

export const SubmitButton: VFC<ButtonProps> = (props) => (
  <Button
    {...props}
    type="submit"
    variant="contained"
    color="primary"
    className={useStyles().submitButton}
    startIcon={<Save />}
  >
    Save
  </Button>
);
