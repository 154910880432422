import { useUpdateClient } from 'src/clients/hooks/useUpdateClient';
import type { Client, ClientReturns } from 'src/clients/types';

export const useUpdateReturns = (record: Client) => {
  const [updateRecord, response] = useUpdateClient(record);

  const updateReturns = (returns: ClientReturns) => {
    const payload: { data: Client } = { data: { ...record, returns } };

    return updateRecord({ payload });
  };

  return { updateReturns };
};
