import type { ButtonProps } from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import type { VFC } from 'react';

export const useListButtonStyles = makeStyles({
  tableButton: {
    marginTop: '1rem',
    marginBottom: '1rem',
    marginRight: '0.5rem',
  },
});

export const DeleteButton: VFC<ButtonProps> = (props) => (
  <Button
    {...props}
    variant="outlined"
    className={useListButtonStyles().tableButton}
    startIcon={<Delete />}
  >
    Delete
  </Button>
);
