import type { FC } from 'react';
import type { TabbedShowLayoutProps } from 'react-admin';
import { TabbedShowLayout } from 'react-admin';
import { useLoadedSnapshotData } from 'src/clients/snapshots/hooks';

type ClientTabbedShowLayout = Omit<TabbedShowLayoutProps, 'tabs'>;

export const ClientTabbedShowLayout: FC<ClientTabbedShowLayout> = ({
  record,
  ...rest
}) => {
  const snapshot = useLoadedSnapshotData();
  const clientData = snapshot ?? record;
  return <TabbedShowLayout {...{ record: clientData, ...rest }} />;
};
