import type { ClientReturns, Distribution } from 'src/clients/types';
import type {
  NZSuperRate,
  Portfolio,
  ReturnEstimates,
  TaxRate,
} from 'src/globals/types';

const INFLATION_RATE = 0.025 as const;

// https://www.ird.govt.nz/income-tax/income-tax-for-individuals/tax-codes-and-tax-rates-for-individuals/tax-rates-for-individuals
const TAX_RATES: TaxRate[] = [
  { ceiling: 14000, rate: 0.105 },
  { ceiling: 48000, rate: 0.175 },
  { ceiling: 70000, rate: 0.3 },
  { ceiling: 180000, rate: 0.33 },
  { ceiling: 'Remaining', rate: 0.39 },
];

// https://www.business.govt.nz/tax-and-accounting/tax-when-you-have-employees/employer-superannuation-contribution-tax-esct/
const KIWISAVER_ESCT_RATES: TaxRate[] = [
  { ceiling: 16800, rate: 0.105 },
  { ceiling: 57600, rate: 0.175 },
  { ceiling: 84000, rate: 0.3 },
  { ceiling: 'Remaining', rate: 0.33 },
];
export const KIWISAVER_RATES: number[] = [0, 0.03, 0.04, 0.06, 0.08, 0.1];

export const KIWISAVER_GOVT_CONTR_RATE = 0.5 as const;
export const KIWISAVER_GOVT_CONTR_MAX = 521.43 as const;
export const KIWISAVER_GOVT_CONTR_MAX_AGE = 65;

// https://sorted.org.nz/guides/retirement/this-years-nz-super-rates/
export const NZ_SUPER_ANNUAL_RATES: NZSuperRate[] = [
  { condition: 'Single Alone', annual_rate: 22721 },
  { condition: 'Single Sharing', annual_rate: 20973 },
  {
    condition: 'Couple, One Qualifies, Partner Not Included',
    annual_rate: 17478,
  },
  { condition: 'Couple, One Qualifies, Partner Included', annual_rate: 33225 },
  { condition: 'Couple Both Qualify', annual_rate: 34955 },
];

export const NZ_SUPER_ELLIGIBLE_AGE = 65;

export const defaultEstimates: ReturnEstimates = {
  min_return: 0,
  avg_return: 0,
  max_return: 0,
};

export const defaultPortfolio: Portfolio = {
  name: 'Custom',
  year1: defaultEstimates,
  year5: defaultEstimates,
  year10: defaultEstimates,
  year20: defaultEstimates,
};

export const defaultDistribution: Distribution = new Array(100).fill(0);

export const defaultReturns: ClientReturns = {
  avg: defaultDistribution,
  min: defaultDistribution,
  max: defaultDistribution,
  solved: true,
};
