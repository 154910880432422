import type { FC } from 'react';
import type { ListProps } from 'react-admin';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  useLocale,
} from 'react-admin';
import { ClientBulkActionButtons } from 'src/clients/ClientList/ClientBulkActionButtons';
import { ClientFilters } from 'src/clients/ClientList/ClientFilters';
import { ClientPagination } from 'src/clients/ClientList/ClientPagination';

export const ClientList: FC<ListProps> = (props) => {
  const locale = useLocale();

  return (
    <List
      {...props}
      filters={<ClientFilters />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      pagination={<ClientPagination />}
      bulkActionButtons={<ClientBulkActionButtons />}
    >
      <Datagrid rowClick="show">
        {/* <TextField source="id" /> */}
        <TextField source="name" />
        <DateField source="birthday" locales={locale} />
        <NumberField source="retirement_age" />
        <TextField source="partner.name" />
        <DateField
          source="partner.birthday"
          locales={locale}
          label="Part. Birthday"
        />
        <NumberField source="partner.retirement_age" label="Part. Ret. Age" />
        <DateField source="createdate" locales={locale} label="Created on" />
      </Datagrid>
    </List>
  );
};
