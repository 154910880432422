import { getInitialAge } from 'src/calc/age/getInitialAge';
import { makeAgesArray } from 'src/calc/age/makeAgesArray';
import { adjustEntryByInflationFn } from 'src/calc/transactions/adjustEntryByInflationFn';
import { combineEntries } from 'src/calc/transactions/combineEntries';
import { expandEntries } from 'src/calc/transactions/expandEntries';
import { addArrays } from 'src/calc/utils/addArrays';
import { getCumulativeSumByAgeArray } from 'src/calc/utils/getCumulativeSumByAgeArray';
import type {
  IncomeEntryExpanded,
  IncomeEntry,
} from 'src/clients/income/types';
import type { ClientData } from 'src/clients/types';

export type MainOrPartner = 'main' | 'partner';
export type MainOrPartnerOrBoth = 'main' | 'partner' | undefined;

export const getIncome = (clientData: ClientData) => ({
  totals: getMainOrPartnerIncome(clientData),
  main: getMainClientIncome(clientData),
  partner: getPartnerIncome(clientData),
});

export const getMainOrPartnerIncome = (
  clientData: ClientData,
  client?: MainOrPartnerOrBoth
) => {
  const {
    incomeEntries = [],
    inflation_rate,
    use_global_inflation,
    globals,
  } = clientData;

  const defaultInflation = 0.025;
  const inflation =
    (use_global_inflation ? globals?.inflation_rate : inflation_rate) ??
    defaultInflation;

  const filtered = filterEntries(incomeEntries, client);

  const initialAge = getInitialAge(clientData);
  const expanded = expandEntries(filtered, initialAge) as IncomeEntryExpanded[];

  const adjusted = expanded.map(
    adjustEntryByInflationFn(inflation, initialAge)
  ) as IncomeEntryExpanded[];

  // combine by title since income doesn't have assets type
  const combined = combineEntries(adjusted, 'title');

  const totals = addArrays(...adjusted.map(({ array }) => array));

  const agesArray = makeAgesArray(totals.length);
  const cumulative = getCumulativeSumByAgeArray(agesArray, totals);
  return {
    transactions: filtered,
    expanded,
    adjusted,
    combined,
    totals,
    cumulative,
  };
};
export const getMainClientIncome = (clientData: ClientData) =>
  getMainOrPartnerIncome(clientData, 'main');

export const getPartnerIncome = (clientData: ClientData) =>
  getMainOrPartnerIncome(clientData, 'partner');

const filterEntries = (entries: IncomeEntry[], client?: MainOrPartnerOrBoth) =>
  entries.filter(({ partner }) =>
    client === 'main'
      ? !partner
      : client === 'partner'
      ? Boolean(partner)
      : true
  );
