import type { ResourceProps } from 'react-admin';
import { ClientCreate } from 'src/clients/ClientCreate/ClientCreate';
import { ClientEdit } from 'src/clients/ClientEdit/ClientEdit';
import { ClientList } from 'src/clients/ClientList/ClientList';
import { ClientShow } from 'src/clients/ClientShow/ClientShow';

export const clients: ResourceProps = {
  name: 'clients',
  show: ClientShow,
  edit: ClientEdit,
  list: ClientList,
  create: ClientCreate,
};
