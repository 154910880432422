import { useDispatch, useSelector } from 'react-redux';
import type { Action, Dispatch } from 'redux';

type LoadedSnapshotId = string | null | undefined;

type LoadSnapshotAction = Action<'SNAPSHOT_LOAD'> & {
  payload?: LoadedSnapshotId;
};
export type SnapshotsReducer = typeof snapshotsReducer;
export type CustomReducers = { snapshot: SnapshotsReducer };

type CustomReducersData = {
  [K in keyof CustomReducers]: ReturnType<CustomReducers[K]>;
};
type DefaultStore = Record<string, unknown>;
type CustomStore = DefaultStore & CustomReducersData;

//[React-admin - Admin and Resource Components](https://marmelab.com/react-admin/Admin.html#customreducers)
export const snapshotsReducer = (
  state: LoadedSnapshotId = null,
  { type, payload }: LoadSnapshotAction
): LoadedSnapshotId => (type === 'SNAPSHOT_LOAD' ? payload : state);

//[Getting Started with React Redux | React Redux](https://react-redux.js.org/introduction/getting-started#hooks)
export const useDispatchSnapshot = () => {
  const dispatch = useDispatch<Dispatch<LoadSnapshotAction>>();
  const dispatchSnapshot = (snapshot: LoadedSnapshotId = null) =>
    dispatch({ type: 'SNAPSHOT_LOAD', payload: snapshot });
  return dispatchSnapshot;
};

export const useLoadedSnapshotId = (): LoadedSnapshotId =>
  useSelector(({ snapshot }: CustomReducersData) => snapshot);
