import dayjs from 'dayjs';
import { getAgeAtYear } from 'src/calc/age/getClientAge';
import {
  getYearAtAge,
  getYearAtPartnerAge,
  getYearOffset,
} from 'src/calc/age/getInitialAge';
import { getMaxCalculationAge } from 'src/calc/age/getMaxCalculationAge';
import {
  getAgeAtEarliestRetirementYear,
  getEarliestRetirementYear,
} from 'src/calc/age/getRetirementAge';
import { adjustEntryByInflationFn } from 'src/calc/transactions/adjustEntryByInflationFn';
import { combineEntries } from 'src/calc/transactions/combineEntries';
import { expandEntries } from 'src/calc/transactions/expandEntries';
import { makeNewTransaction } from 'src/clients/transactions/functions/makeNewTransaction';
import type {
  NZSuperTransactionTitle,
  Transaction,
} from 'src/clients/transactions/types';
import type { ClientData } from 'src/clients/types';
import { NZ_SUPER_ELLIGIBLE_AGE } from 'src/globals/defaults';

const NZSUPER_EMPTY_RETURN = {
  transactions: [],
  expanded: [],
  adjusted: [],
  combined: [],
  tests: { allTransactions: [] },
};

export const getNZSuperAuto = (clientData: ClientData, force = false) => {
  const {
    partner,
    globals: {
      nz_super: { rates, base_date },
    },
    start_date,
    inflation_rate,
    nz_super_auto,
  } = clientData;

  if (!nz_super_auto && !force) return NZSUPER_EMPTY_RETURN;

  //#TODO handle huge disparities in age

  const yearElligible = getYearAtAge(clientData, NZ_SUPER_ELLIGIBLE_AGE);
  const yearPartnerElligible =
    getYearAtPartnerAge(clientData, NZ_SUPER_ELLIGIBLE_AGE) ?? yearElligible;

  // year the oldest partner turns 65
  // e.g. main 65, partner 67 - starts at year 62 (when partner turns 65)
  const earliestElligibleYear = Math.min(yearElligible, yearPartnerElligible);
  // year the youngest partner turns 65
  // e.g. main 65, patner 63 - starts at year 67 (when partner turns 65)
  const latestElligibleYear = Math.max(yearElligible, yearPartnerElligible);

  const ageAtEarliestElligibleYear = getAgeAtYear(
    clientData,
    earliestElligibleYear
  );
  const ageAtLatestElligibleYear = getAgeAtYear(
    clientData,
    latestElligibleYear
  );

  const partnersRetireInDifferentYears =
    ageAtEarliestElligibleYear !== ageAtLatestElligibleYear;

  const maxYearTo = Math.max(
    ageAtLatestElligibleYear,
    getMaxCalculationAge(clientData)
  );

  const singleValue =
    rates.find(({ condition }) => condition === 'Single Alone')?.annual_rate ??
    0;
  const singleTransaction =
    !partner &&
    makeNewTransaction({
      asset: 'NZ Super',
      inflation_adjusted: true,
      recurring: true,
      title: `NZ Super Single Alone` as NZSuperTransactionTitle,
      type: 'Income During Retirement',
      value: singleValue,
      year_from: ageAtEarliestElligibleYear,
      year_to: maxYearTo,
    });

  const coupleOneQualifiesValue =
    rates.find(
      ({ condition }) =>
        condition === 'Couple, One Qualifies, Partner Not Included'
    )?.annual_rate ?? 0;
  const coupleOneQualifiesTransaction =
    !!partner &&
    partnersRetireInDifferentYears &&
    makeNewTransaction({
      asset: 'NZ Super',
      inflation_adjusted: true,
      recurring: true,
      title:
        `NZ Super Couple, One Qualifies, Partner Not Included` as NZSuperTransactionTitle,
      type: 'Income During Retirement',
      value: coupleOneQualifiesValue,
      // starts the year the oldest partner turns 65
      year_from: ageAtEarliestElligibleYear,
      // stops the year before the youngest partner turns 65
      year_to: ageAtLatestElligibleYear - 1,
    });

  const coupleBothQualifyValue =
    rates.find(({ condition }) => condition === 'Couple Both Qualify')
      ?.annual_rate ?? 0;
  const coupleBothQualifyTransaction =
    !!partner &&
    makeNewTransaction({
      asset: 'NZ Super',
      inflation_adjusted: true,
      recurring: true,
      title: `NZ Super Couple Both Qualify` as NZSuperTransactionTitle,
      type: 'Income During Retirement',
      value: coupleBothQualifyValue,
      // starts the year the youngest partner turns 65
      year_from: ageAtLatestElligibleYear,
      year_to: maxYearTo,
    });

  const allTransactions = [
    singleTransaction,
    coupleOneQualifiesTransaction,
    coupleBothQualifyTransaction,
  ];
  const transactions = allTransactions.filter(Boolean) as Transaction[];

  const expanded = expandEntries(transactions);

  // calculate the inflation rate offset
  // first we find the exact date of retirement (either client)
  const ageAtEarliestRetirementYear =
    getAgeAtEarliestRetirementYear(clientData);
  const earliestRetirementYear = getEarliestRetirementYear(clientData);
  const exactDateOfRetirement = dayjs(start_date).year(earliestRetirementYear);

  // then compare that against the base_date from globals
  const inflationOffset = getYearOffset(exactDateOfRetirement, base_date);

  // finally, `adjustEntryByInflationFn` expects `offset` to be an index value comparable to the client's age
  const inflationOffsetAge = ageAtEarliestRetirementYear - inflationOffset;

  const adjusted = expanded.map(
    adjustEntryByInflationFn(inflation_rate, inflationOffsetAge)
  );

  const combined = combineEntries(adjusted);

  return {
    transactions,
    expanded,
    adjusted,
    combined,
    tests: { allTransactions },
  };
};
