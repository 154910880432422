import { makeAgesArray } from 'src/calc/age/makeAgesArray';
import { findMaxEntryLength } from 'src/calc/transactions/findMaxEntryLength';
import { getKiwiSaver } from 'src/calc/transactions/getKiwiSaver';
import { getTransactions } from 'src/calc/transactions/getTransactions';
import { addArrays } from 'src/calc/utils/addArrays';
import { getCumulativeSumByAgeArray } from 'src/calc/utils/getCumulativeSumByAgeArray';
import type { Transaction } from 'src/clients/transactions/types';
import type { ClientData } from 'src/clients/types';

export const getContributions = (clientData: ClientData) => {
  const filterFn = ({ type, asset }: Transaction) =>
    type === 'Contribution' &&
    asset !== 'KiwiSaver' &&
    asset !== 'KiwiSaver - Partner';

  const contributions = getTransactions(clientData, filterFn);

  const kiwisaver = getKiwiSaver(clientData);

  // helpers for calculating totals
  const maxLength = Math.max(
    findMaxEntryLength(contributions.adjusted),
    kiwisaver.totals.total.length
  );
  const agesArray = makeAgesArray(maxLength);

  // totals arrays
  const yearlyTotal = agesArray.map((age) =>
    [...contributions.adjusted].reduce(
      (acc, { array }) => acc + (array[age] ?? 0),
      0
    )
  );
  const cumulativeTotal = getCumulativeSumByAgeArray(agesArray, yearlyTotal);

  //
  // KIWISAVER INCLUDED
  //

  // helpers for calculating totals
  const maxLengthWithKiwisaver = Math.max(
    findMaxEntryLength(contributions.adjusted),
    kiwisaver.totals.total.length
  );
  const agesArrayWithKiwisaver = makeAgesArray(maxLengthWithKiwisaver);

  // totals arrays
  const totalWithKiwisaver = addArrays(yearlyTotal, kiwisaver.totals.total);
  const cumulativeTotalWithKiwisaver = getCumulativeSumByAgeArray(
    agesArrayWithKiwisaver,
    totalWithKiwisaver
  );

  return {
    transactions: contributions,
    totals: {
      yearly: yearlyTotal,
      cumulative: cumulativeTotal,
      withKiwisaver: {
        yearly: totalWithKiwisaver,
        cumulative: cumulativeTotalWithKiwisaver,
      },
    },
  };
};
