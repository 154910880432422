import { getInitialAge } from 'src/calc/age/getInitialAge';
import { getRetirementIncome } from 'src/calc/transactions/getRetirementIncome';
import { makeAgeGridData } from 'src/clients/tables/functions/makeAgeGridData';
import { makeGridColumns } from 'src/clients/tables/functions/makeGridColumns';
import { makeGridRows } from 'src/clients/tables/functions/makeGridRows';
import { makeTableDataFromEntry } from 'src/clients/tables/functions/makeTableDataFromEntry';
import { makeYearGridData } from 'src/clients/tables/functions/makeYearGridData';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';

export const makeRetirementGridData = (clientData: ClientData) => {
  // get contributions from clientData
  const {
    transactions,
    NZSuperAuto,
    totals: { yearlyIncome, shortfall, budget, withdrawals, salary },
  } = getRetirementIncome(clientData);

  // shows age array as first column of the grid
  const maxLength = shortfall.length;
  const { ageColumn, agesArray } = makeAgeGridData(maxLength);
  const { yearColumn } = makeYearGridData(clientData, agesArray);

  const retirementBudgetColumn: TransactionTableData = {
    id: 'budget',
    title: 'Retirement Budget',
    array: budget,
  };
  const withdrawalsColumn: TransactionTableData = {
    id: 'withdrawals',
    title: 'Total Withdrawals',
    array: withdrawals,
  };
  const totalRetirementIncomeColumn: TransactionTableData = {
    id: 'totalIncome',
    title: 'Income from all sources',
    array: yearlyIncome,
  };
  const salaryColumn: TransactionTableData = {
    id: 'salaryIncome',
    title: 'Salary Income',
    array: salary,
  };
  const shortfallColumn: TransactionTableData = {
    id: 'total',
    title: 'Shortfall in Income Required',
    array: shortfall,
  };

  const transactionColumns: TransactionTableData[] = transactions.combined.map(
    makeTableDataFromEntry()
  );
  const NZSuperAutoColumns: TransactionTableData[] =
    NZSuperAuto?.combined.map(makeTableDataFromEntry()) ?? [];

  const allColumns: TransactionTableData[] = [
    yearColumn,
    ageColumn,
    retirementBudgetColumn,
    withdrawalsColumn,
    ...transactionColumns,
    ...NZSuperAutoColumns,
    totalRetirementIncomeColumn,
    salaryColumn,
    shortfallColumn,
  ];

  // As requested on 14/04/2022
  // we now allow retiremen budget transactionst to start from the initial age
  const initialAge = getInitialAge(clientData);
  const rows = makeGridRows(maxLength, allColumns, initialAge);
  const columns = makeGridColumns(allColumns, clientData);

  return { rows, columns, allColumns };
};
