import { getInitialAge } from 'src/calc/age/getInitialAge';
import { makeAgesArray } from 'src/calc/age/makeAgesArray';
import { makeReturnsExpanded, zeroArray } from 'src/calc/returns/utils';
import { getAssets } from 'src/calc/transactions/getAssets';
import { getContributions } from 'src/calc/transactions/getContributions';
import { getRetirementIncome } from 'src/calc/transactions/getRetirementIncome';
import { getWithdrawals } from 'src/calc/transactions/getWithdrawals';
import { addArrays } from 'src/calc/utils/addArrays';
import type { ClientData, ReturnBands } from 'src/clients/types';
import { defaultReturns } from 'src/globals/defaults';

export const getCashflow = (clientData: ClientData) => {
  // CONTRIBUTIONS

  // Per client request, we split money going into the account prior and from retirement age for display purposes
  const totalContributions =
    getContributions(clientData).totals.withKiwisaver.yearly;

  // WITHDRAWALS
  const totalWithdrawals = getWithdrawals(clientData).totals.yearly;

  // RETIREMENT INCOME
  // a new Transaction Type 'Retirement Income was created to account for money coming in during retirement years
  // budgetShortfall is the amount of money saved (if positive) or drawn from the investments (if negative) during retirement
  const { budgetShortfall: retirementShortfall } =
    getRetirementIncome(clientData).totals;

  // helpers
  // remember that transaction lengths might not be the same so we need to simulate for whatever is longer
  const maxLength = Math.max(
    totalContributions.length,
    totalWithdrawals.length,
    retirementShortfall.length
  );
  const agesArray = makeAgesArray(maxLength);

  const totalNetPerYear = getTotalNetPerYear(
    agesArray,
    totalContributions,
    totalWithdrawals,
    retirementShortfall
  );

  // small hack to make assetsArray as long as the cashflow duration
  const assetsArray = addArrays(
    getAssets(clientData).array,
    zeroArray(maxLength)
  );

  // partial cumulative total, used for calculating ROI
  const cumulativeNetPlusAssets = cumulativeArraySum(
    addArrays(totalNetPerYear, assetsArray)
  );

  const { returns } = clientData;

  const getReturnsInDollars = (band: ReturnBands) => {
    const roiPercent = returns?.[band] || defaultReturns;

    // roi each year, dollar value
    const [roiAmount] = getRoiAmountPerYear(
      // As per requested on 07/05/2022, year net doesn't go into roi amount, only into the cumulative total
      // addArrays(totalNetPerYear, assetsArray),
      assetsArray,
      totalNetPerYear,
      roiPercent
    );

    // cumulative total
    const cumulative = cumulativeArraySum(
      addArrays(totalNetPerYear, assetsArray, roiAmount)
    );

    return {
      roiPercent,
      roiAmount,
      cumulative,
    };
  };

  const start = getInitialAge(clientData);
  const expandedReturns = makeReturnsExpanded(returns, start);

  return {
    contributions: totalContributions,
    withdrawals: totalWithdrawals,
    net: totalNetPerYear,
    returns: {
      clientReturns: returns,
      expandedReturns,
      avg: getReturnsInDollars('avg'),
      min: getReturnsInDollars('min'),
      max: getReturnsInDollars('max'),
    },
  };
};

export const getTotalNetPerYear = (
  agesArray: number[],
  totalContributions: number[],
  totalWithdrawals: number[],
  retirementShortfall: number[]
) =>
  agesArray.map(
    (y) =>
      (totalContributions[y] || 0) -
      (totalWithdrawals[y] || 0) +
      (retirementShortfall[y] || 0)
  );

export const getCumulativeNetPerYear = (
  agesArray: number[],
  totalNetPerYear: number[]
) =>
  agesArray.reduce(
    (acc, age) => [...acc, (acc[age - 1] ?? 0) + totalNetPerYear[age]],
    [] as number[]
  );

export const getRoiAmountPerYear = (
  cumulativeNet: number[],
  totalNet: number[],
  roiPercent: number[]
): [number[], number] =>
  cumulativeNet.reduce(
    ([roi, total], value, y) => {
      // first year builds on total net, following years builds on previous year
      const roiAmountThisYear = (total + value) * roiPercent[y];
      const roiAmountPerYear = [...roi, roiAmountThisYear];
      // As per requested on 07/05/2022, year net doesn't go into roi amount, only into the cumulative total
      const cumulativeTotal = total + value + roiAmountThisYear + totalNet[y];
      return [roiAmountPerYear, cumulativeTotal];
    },
    [[], 0] as [number[], number]
  );

export const getCumulativeTotalPerYear = (
  agesArray: number[],
  assetsArray: number[],
  totalNet: number[],
  roiAmount: number[]
) =>
  agesArray.reduce(
    (acc, age) => [
      ...acc,
      // appends previous year total + returns
      (acc[age - 1] || 0) + assetsArray[age] + totalNet[age] + roiAmount[age],
    ],
    [] as number[]
  );

export const cumulativeArraySum = (
  array: number[],
  initialValue = 0
): number[] => {
  const helper = new Array(array.length).fill(0);

  return helper.reduce(
    (acc, _, i) => [...acc, (acc[i - 1] || 0) + array[i]],
    []
  );
};
