import type { GridColDef, GridColumns } from '@material-ui/data-grid';
import { getInitialAge } from 'src/calc/age/getInitialAge';
import { getCashflow } from 'src/calc/transactions/getCashflow';
import { getRetirementIncome } from 'src/calc/transactions/getRetirementIncome';
import { addArrays } from 'src/calc/utils/addArrays';
import { makeAgeGridData } from 'src/clients/tables/functions/makeAgeGridData';
import {
  gridCurrencyFormatter,
  isCurrencyColumn,
} from 'src/clients/tables/functions/makeGridColumns';
import { makeGridRows } from 'src/clients/tables/functions/makeGridRows';
import { makeYearGridData } from 'src/clients/tables/functions/makeYearGridData';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';

export const makeCashflowReportGridData = (clientData: ClientData) => {
  // get contributions from clientData
  const {
    contributions,
    returns: {
      avg: { roiAmount, roiPercent, cumulative },
    },
  } = getCashflow(clientData);

  const {
    totals: { withdrawalsBeforeRetirement, shortfall },
  } = getRetirementIncome(clientData);
  // shows age array as first column of the grid

  const { ageColumn, agesArray } = makeAgeGridData(cumulative.length);
  const { yearColumn } = makeYearGridData(clientData, agesArray);

  const scheduledWithdrawals = addArrays(
    negArray(withdrawalsBeforeRetirement),
    shortfall
  );

  const roiPercentColumn: TransactionTableData = {
    id: 'roiPercent',
    title: 'Yield',
    array: roiPercent,
  };
  const roiAmountColumn: TransactionTableData = {
    id: 'roiAmount',
    title: 'Return on Investment',
    array: roiAmount,
  };
  const totalContributionsColumn: TransactionTableData = {
    id: 'totalContr',
    title: 'Scheduled Deposits',
    array: contributions,
  };
  const totalWithdrawalsColumn: TransactionTableData = {
    id: 'totalWith',
    title: 'Scheduled Withdrawals',
    array: scheduledWithdrawals,
  };
  const cumulativeTotalColumn: TransactionTableData = {
    id: 'cumTotal',
    title: 'Cumulative Total',
    array: cumulative,
  };

  const allColumns: TransactionTableData[] = [
    yearColumn,
    ageColumn,
    roiPercentColumn,
    roiAmountColumn,
    totalContributionsColumn,
    totalWithdrawalsColumn,
    cumulativeTotalColumn,
  ];

  const initialAge = getInitialAge(clientData);

  const rows = makeGridRows(cumulative.length, allColumns, initialAge);
  const columns: GridColumns = allColumns.map(({ id, title }) => ({
    field: id,
    headerName: title,
    sortable: false,
    width: isCurrencyColumn(id) ? 200 : 100,

    valueFormatter:
      id === 'roiPercent'
        ? roiPercentFormatter
        : isCurrencyColumn(id)
        ? gridCurrencyFormatter
        : undefined,
  }));

  return { rows, columns, allColumns };
};

const roiFormatter =
  (roiPercent: number[]): GridColDef['valueFormatter'] =>
  (params) =>
    `${((params?.value ?? 0) as number).toLocaleString('en-NZ', {
      style: 'currency',
      currency: 'NZD',
    })} (${percentFormatter(roiPercent[params.rowIndex], 1)})`;

const roiPercentFormatter: GridColDef['valueFormatter'] = (params) =>
  percentFormatter((params?.value ?? 0) as number);

const negArray = (arr: number[]) => arr.map((n) => -n);

export const percentFormatter = (n: number, digits = 2): string =>
  `${(n * 100).toFixed(digits)}%`;
