import type { ClientData, Age } from 'src/clients/types';

/** Find at what age/year calculations will stop. Not considering NZ Super auto transactions.*/
export const getMaxCalculationAge = (clientData: ClientData): Age => {
  const { incomeEntries, transactions } = clientData;

  const allTransactions = [...incomeEntries, ...transactions];
  const maxAge = allTransactions.reduce(
    (max, { year_from, year_to }) => Math.max(max, year_from, year_to ?? 0),
    0
  );

  return maxAge;
};
