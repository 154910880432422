import type { GridColumns } from '@material-ui/data-grid';
import { gridCurrencyFormatter } from 'src/clients/tables/functions/makeGridColumns';
import { ageColumnSortComparator } from 'src/clients/transactions/functions/ageColumnSortComparator';
import { AgeCell } from 'src/clients/transactions/RenderCells';
import type { ClientData } from 'src/clients/types';

export const makeIncomeColumns = (clientData: ClientData): GridColumns => [
  {
    field: 'partner',
    headerName: 'Client',
    width: 150,
    sortable: false,
    valueFormatter: ({ value }) =>
      value ? clientData.partner?.name : clientData.name,
  },
  {
    field: 'age',
    headerName: 'Age',
    renderCell: AgeCell,
    width: 120,
    sortable: false,
    sortComparator: ageColumnSortComparator,
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 150,
    sortable: false,
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 150,
    sortable: false,
    valueFormatter: gridCurrencyFormatter,
  },
  {
    field: 'inflation_adjusted',
    headerName: 'Inflation-Adj.',
    width: 150,
    sortable: false,
  },
  {
    field: 'kiwisaver',
    headerName: 'KiwiSaver %',
    width: 150,
    sortable: false,
    valueFormatter: ({ value }) => (value as number) * 100,
  },
];
