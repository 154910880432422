import type { EntryTypeExpanded } from 'src/clients/transactions/types';

/**
 * Generates a function to be used over an entry array
 *
 * if inflation_adjusted is false, doesn't do anything
 *
 * offset is usually the initial age of calculations
 *
 * @param rate
 * @returns
 */
export const adjustEntryByInflationFn =
  (rate: number, offset = 0) =>
  <T extends EntryTypeExpanded>({ array, inflation_adjusted, ...rest }: T): T =>
    ({
      ...rest,
      array: inflation_adjusted
        ? adjustArrayByInflation(array, rate, offset)
        : array,
      inflation_adjusted,
    } as T);

export const adjustArrayByInflation = (
  array: number[],
  rate: number,
  offset = 0
): number[] => array.map(adjustByRateFn(rate, offset));

// just a helper for the map above
// offsets calculations to start applying interest from initialAge
const adjustByRateFn =
  (rate: number, offset = 0) =>
  (value: number, year: number) =>
    adjustValueByInflation(
      rate,
      value,
      // Math.max(0, year - Math.max(0, offset))
      year - offset
    );

const adjustValueByInflation = (
  rate: number,
  value: number,
  year: number
): number => value * (1 + rate) ** year;
