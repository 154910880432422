import { getInitialAge } from 'src/calc/age/getInitialAge';
import type { ClientData } from 'src/clients/types';

export const getAssets = (clientData: ClientData) => {
  const { assets = [] } = clientData;

  const total = assets.reduce((acc, { value }) => acc + (value || 0), 0);

  // returns an array with the assets over time,
  // basically all zeros except for the initialAge
  // something like [0,0,0,... totals]

  const initialAge = getInitialAge(clientData);
  const array: number[] = new Array(initialAge + 1)
    .fill(0)
    .fill(total, initialAge, initialAge + 1);

  return { total, array };
};
