import type { GridColumns } from '@material-ui/data-grid';

export const makeAssetsColumns = (): GridColumns => [
  {
    field: 'title',
    headerName: 'Asset',
    width: 150,
    sortable: false,
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 150,
    sortable: false,
    valueFormatter: ({ value }) =>
      value?.toLocaleString('en-NZ', { style: 'currency', currency: 'NZD' }),
  },
];
