import { getAgeAtPartnerAge } from 'src/calc/age/getClientAge';
import { makeAgesArray } from 'src/calc/age/makeAgesArray';
import type {
  MainOrPartner,
  MainOrPartnerOrBoth,
} from 'src/calc/transactions/getIncome';
import { getMainOrPartnerIncome } from 'src/calc/transactions/getIncome';
import { getTransactions } from 'src/calc/transactions/getTransactions';
import { addArrays } from 'src/calc/utils/addArrays';
import { getCumulativeSumByAgeArray } from 'src/calc/utils/getCumulativeSumByAgeArray';
import type { Age, ClientData } from 'src/clients/types';
import {
  KIWISAVER_GOVT_CONTR_MAX,
  KIWISAVER_GOVT_CONTR_MAX_AGE,
  KIWISAVER_GOVT_CONTR_RATE,
} from 'src/globals/defaults';

export const getKiwiSaver = (clienData: ClientData) => {
  const main = getMainClientKiwisaver(clienData);
  const partner = getPartnerKiwisaver(clienData);

  const totals = Object.entries(main).reduce(
    (obj, [key, value]) => ({
      ...obj,
      [key]: addArrays(value, partner[key as keyof typeof partner]),
    }),
    {} as typeof main
  );

  return { main, partner, totals };
};

const getVoluntaryKiwisaver = (
  clientData: ClientData,
  mainOrPartner: MainOrPartner = 'main'
) => {
  const kiwisaverVoluntaryTransactions = getTransactions(
    clientData,
    ({ asset }) =>
      mainOrPartner === 'main'
        ? asset === 'KiwiSaver'
        : asset === 'KiwiSaver - Partner'
  );
  const kiwisaverVoluntaryArray =
    kiwisaverVoluntaryTransactions.adjusted[0]?.array || [];

  return kiwisaverVoluntaryArray;
};

export const getMainVoluntaryKiwiSaver = (clientData: ClientData) =>
  getVoluntaryKiwisaver(clientData, 'main');

export const getPartnerVoluntaryKiwiSaver = (clientData: ClientData) =>
  getVoluntaryKiwisaver(clientData, 'partner');

export const getIncomeKiwisaver = (
  clientData: ClientData,
  client?: MainOrPartnerOrBoth
) => {
  // we can't use combineEntries here because each entry might have different kiwisaver values ...
  const salaryEntries = getMainOrPartnerIncome(clientData, client).adjusted;

  // just for display on the tables
  const totalSalary = addArrays(...salaryEntries.map(({ array }) => array));

  // ... instead we just add the results
  const employeeContributions = addArrays(
    ...salaryEntries.map(({ array, kiwisaver }) =>
      array.map((value) => value * kiwisaver)
    )
  );

  const voluntaryContributions = getVoluntaryKiwisaver(clientData, client);

  const govtContributionsMaxYear: Age =
    client === 'main'
      ? KIWISAVER_GOVT_CONTR_MAX_AGE
      : getAgeAtPartnerAge(clientData, KIWISAVER_GOVT_CONTR_MAX_AGE)!;

  const govtContributions = addArrays(
    employeeContributions,
    voluntaryContributions
  )
    .map((value) =>
      Math.min(value * KIWISAVER_GOVT_CONTR_RATE, KIWISAVER_GOVT_CONTR_MAX)
    )
    .slice(0, govtContributionsMaxYear);

  const EMPLOYER_PERCENTAGE = 0.03;
  const employerContributions = addArrays(
    ...salaryEntries.map(({ array, kiwisaver }) =>
      array.map(
        (value) =>
          Math.ceil(kiwisaver) * // this filters if the employee is contributing
          value *
          EMPLOYER_PERCENTAGE *
          (1 - ESCT(value, clientData))
      )
    )
  );

  const totalContributions = addArrays(
    employeeContributions,
    employerContributions,
    voluntaryContributions,
    govtContributions
  );

  // helpers for calculating totals
  const agesArray = makeAgesArray(totalContributions.length);
  const cumulativeTotal = getCumulativeSumByAgeArray(
    agesArray,
    totalContributions
  );

  return {
    totalSalary,
    employee: employeeContributions,
    employer: employerContributions,
    voluntary: voluntaryContributions,
    govt: govtContributions,
    total: totalContributions,
    cumulative: cumulativeTotal,
  };
};

const getMainClientKiwisaver = (clientData: ClientData) =>
  getIncomeKiwisaver(clientData, 'main');

const getPartnerKiwisaver = (clientData: ClientData) =>
  getIncomeKiwisaver(clientData, 'partner');

//https://www2.deloitte.com/nz/en/pages/tax-alerts/articles/time-to-review-your-employer-superannuation-contribution-tax-rates.html
const ESCT = (salary: number, clientData: ClientData) => {
  const {
    globals: { income_tax_rates },
  } = clientData;

  const DEFAULT_RATE = 0.3;
  const bracket = income_tax_rates.find(({ ceiling }) => ceiling > salary);

  const rate = bracket?.rate ?? DEFAULT_RATE;
  return rate;
};
