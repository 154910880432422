import type { ClientData } from 'src/clients/types';

/**
 * List all asset types found in the client's transactions
 *
 * @param clientData
 * @returns
 */
export const getTransactionAssets = (clientData: ClientData): string[] => {
  const { transactions = [], assets = [] } = clientData;

  // normalise
  const transactionAssets = transactions.map(({ asset }) => ({
    title: asset,
  }));

  const assetOptions = [...transactionAssets, ...assets]
    .reduce(
      (set, { title }) => (set.includes(title) ? set : [...set, title]),
      hardcodedAssets as string[]
    )
    .sort();

  // const options = assets.map((asset) => ({ label: asset, value: asset }));
  return assetOptions;
};

const hardcodedAssets = [
  'KiwiSaver',
  'KiwiSaver - Partner',
  'NZ Super',
  'Retirement Budget',
  'Other',
];
