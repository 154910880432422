import { useGetClientById } from 'src/clients/hooks/useGetClientById';
import type { ClientData } from 'src/clients/types';

export const useClientPageTitle = (id?: string) => {
  const clientData = useGetClientById(id);
  if (!clientData?.name) return id;
  const title = makeTitle(clientData);
  return title;
};

export const makeTitle = (clientData: ClientData) => {
  const mainName = clientData?.name;
  const partnerName = clientData?.partner?.name;
  const title = `${mainName}${partnerName ? ` & ${partnerName}` : ''}`;
  return title;
};
