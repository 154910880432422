import type { OnSuccess, UseMutationValue } from 'react-admin';
import type { Asset } from 'src/clients/assets/types';
import { useUpdateClient } from 'src/clients/hooks/useUpdateClient';
import type { IncomeEntry } from 'src/clients/income/types';
import type { Transaction } from 'src/clients/transactions/types';
import type { Client } from 'src/clients/types';
import type { Snapshot } from 'src/snapshots/types';

type ArrayNames = Extract<
  keyof Client,
  'transactions' | 'incomeEntries' | 'assets' | 'snapshots'
>;

type InferElement<Name extends ArrayNames> = Client[Name][number];
type ArrayElement = InferElement<ArrayNames>;

type UpsertArrayElementFunction<Element extends ArrayElement> = (
  element: Element,
  onSuccess?: OnSuccess
) => ReturnType<UseMutationValue[0]>;

type DeleteArrayElementsFunction<Element extends ArrayElement> = (
  ids: Element['id'][],
  onSuccess?: OnSuccess
) => ReturnType<UseMutationValue[0]>;

type UseUpdateArrayElementsValue = {
  upsertTransaction: UpsertArrayElementFunction<Transaction>;
  deleteTransactions: DeleteArrayElementsFunction<Transaction>;
  upsertIncomeEntry: UpsertArrayElementFunction<IncomeEntry>;
  deleteIncomeEntries: DeleteArrayElementsFunction<IncomeEntry>;
  upsertAsset: UpsertArrayElementFunction<Asset>;
  deleteAssets: DeleteArrayElementsFunction<Asset>;
  upsertSnapshot: UpsertArrayElementFunction<Snapshot>;
  deleteSnapshots: DeleteArrayElementsFunction<Snapshot>;
  response: UseMutationValue[1];
};

/**
 * Hook to allow array mutations
 *
 * @param record a client record
 * @returns mutation functions
 */
export const useUpdateArrayElements = (
  record: Client
): UseUpdateArrayElementsValue => {
  // create mutation function
  const [updateRecord, response] = useUpdateClient(record);

  /**
   * Generic higher-order upsert function for any array types
   *
   * @param arrayName transaction, incomeEntry?
   * @param element element to be upserted (transaction, incomeEntry)
   * @param onSuccess callback after db operation
   * @returns the updated client record
   */
  const upsertElement =
    <
      Name extends ArrayNames,
      Element extends ArrayElement = InferElement<Name>
    >(
      arrayName: Name
    ): UpsertArrayElementFunction<Element> =>
    (newElement, onSuccess) => {
      const elements = (record?.[arrayName] || []) as Element[];

      // replace existing with new data
      const existingIndex = elements.findIndex(
        ({ id }) => id === newElement.id
      );
      const updatedArray =
        existingIndex === -1
          ? [...elements, newElement]
          : elements.map((element, i) =>
              i === existingIndex ? newElement : element
            );

      const payload: { data: Client } = {
        data: {
          ...record,
          [arrayName]: updatedArray,
        },
      };

      // execute db call
      return updateRecord({ payload }, { onSuccess });
    };

  // define upsert for specific array types
  const upsertTransaction = upsertElement('transactions');
  const upsertIncomeEntry = upsertElement('incomeEntries');
  const upsertAsset = upsertElement('assets');
  const upsertSnapshot = upsertElement('snapshots');

  /**
   * Generic higher-order delete function for any array types
   *
   * @param arrayName transaction, incomeEntry, asset, snapshot?
   * @param ids an array of tx ids
   * @param onSuccess callback after db operation
   * @returns the updated client record
   */
  const deleteElements =
    <
      Name extends ArrayNames,
      Element extends ArrayElement = InferElement<Name>
    >(
      arrayName: Name
    ): DeleteArrayElementsFunction<Element> =>
    (ids, onSuccess) => {
      const elements = (record?.[arrayName] || []) as Element[];

      const updatedArray = elements.filter(({ id }) => !ids.includes(id));

      const payload: { data: Client } = {
        data: {
          ...record,
          [arrayName]: updatedArray,
        },
      };

      // execute db call
      return updateRecord({ payload }, { onSuccess });
    };

  // define delete for specific array types
  const deleteTransactions = deleteElements('transactions');
  const deleteIncomeEntries = deleteElements('incomeEntries');
  const deleteAssets = deleteElements('assets');
  const deleteSnapshots = deleteElements('snapshots');

  return {
    upsertTransaction,
    deleteTransactions,
    upsertIncomeEntry,
    deleteIncomeEntries: deleteIncomeEntries,
    upsertAsset,
    deleteAssets,
    upsertSnapshot,
    deleteSnapshots,
    response,
  };
};
