import type { AssetOrTitle } from 'src/calc/transactions/combineEntries';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { EntryTypeExpanded } from 'src/clients/transactions/types';

export const makeTableDataFromEntry =
  (useAsHeader: AssetOrTitle = 'asset') =>
  ({ id, array, ...rest }: EntryTypeExpanded): TransactionTableData => ({
    id,
    title: rest[useAsHeader],
    array,
  });
