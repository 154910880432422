import dayjs from 'dayjs';
import type { FC } from 'react';
import type { CreateProps } from 'react-admin';
import { Create, SimpleForm } from 'react-admin';
import { ClientCreateToolbar } from 'src/clients/ClientCreate/ClientCreateToolbar';
import {
  ClientForm,
  validateClientForm,
} from 'src/clients/ClientEdit/ClientEdit';
import type { Client } from 'src/clients/types';
import { defaultPortfolio, defaultReturns } from 'src/globals/defaults';
import { useGlobals } from 'src/globals/hooks';
import { sampleGlobals } from 'src/utils/testData';

export const ClientCreate: FC<CreateProps> = (props) => {
  const { data: globals = sampleGlobals } = useGlobals();

  if (!globals) return null;
  const defaultValues: Partial<Client> = {
    start_date: dayjs().format('YYYY-MM-DD'),
    assets: [],
    incomeEntries: [],
    portfolio: defaultPortfolio,
    returns: defaultReturns,
    retirement_age: 65,
    snapshots: [],
    transactions: [],
    globals,
    use_global_inflation: true,
    inflation_rate: globals?.inflation_rate,
    nz_super_auto: true,
  };

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={defaultValues}
        validate={validateClientForm}
        toolbar={<ClientCreateToolbar />}
      >
        <ClientForm />
      </SimpleForm>
    </Create>
  );
};
