import { DataGrid } from '@material-ui/data-grid';
import type { VFC } from 'react';
import { useInputDataGridStyles } from 'src/clients/assets/AssetListLive';
import { useLoadedSnapshotData } from 'src/clients/snapshots/hooks';
import { isRetirementTransaction } from 'src/clients/transactions/functions/helpers';
import { makeTransactionsColumns } from 'src/clients/transactions/functions/makeTransactionsColumns';
import type { TransactionListProps } from 'src/clients/transactions/TransactionList';

export const TransactionListSnapshots: VFC<TransactionListProps> = ({
  retirement = false,
}) => {
  const classes = useInputDataGridStyles();
  const clientData = useLoadedSnapshotData();
  if (!clientData) return null;
  const { transactions = [] } = clientData;
  const rows = transactions.filter(
    ({ type }) => retirement === isRetirementTransaction(type)
  );
  return (
    <div>
      <DataGrid
        columns={makeTransactionsColumns()}
        rows={rows}
        rowHeight={40}
        autoHeight
        density="compact"
        disableSelectionOnClick
        className={classes.datagrid}
        sortModel={[
          { field: 'type', sort: 'asc' },
          { field: 'age', sort: 'asc' },
          { field: 'asset', sort: 'asc' },
        ]}
      />
    </div>
  );
};
