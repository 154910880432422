import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import type { VFC } from 'react';
import { useMemo, useState } from 'react';
import type { MainOrPartnerOrBoth } from 'src/calc/transactions/getIncome';
import { useSnapshotOrRecordContext } from 'src/clients/snapshots/hooks';
import { makeIncomeGridData } from 'src/clients/tables/functions/makeIncomeGridData';
import { TableBase } from 'src/clients/tables/TableBase';

export const TableIncome: VFC = () => {
  const { clientData } = useSnapshotOrRecordContext();
  const [value, setValue] = useState<MainOrPartnerOrBoth>();

  const gridData = useMemo(
    () => makeIncomeGridData(clientData, value),
    [value]
  );
  const handleChange = (event: any, newValue: MainOrPartnerOrBoth) =>
    setValue(newValue ? newValue : undefined);

  if (!clientData) return null;

  return (
    <TabContext value={value ?? ''}>
      <TabList onChange={handleChange}>
        <Tab label="Total" value="" />
        <Tab label="Main Client" value="main" />
        {clientData.partner ? (
          <Tab label="Partner" value="partner" />
        ) : (
          // hack needed because a null child breaks TabList
          <></>
        )}
      </TabList>
      <TabPanel value="">
        <TableBase {...gridData} />
      </TabPanel>
      <TabPanel value="main">
        <TableBase {...gridData} />
      </TabPanel>
      <TabPanel value="partner">
        <TableBase {...gridData} />
      </TabPanel>
    </TabContext>
  );
};
