import type { EntryType, Expanded } from 'src/clients/transactions/types';

export const expandEntries = <T extends EntryType = EntryType>(
  entries: T[],
  offset?: number
): Expanded<T>[] => entries.map(expandEntry(offset));

/**
 * @returns tx with expanded array of values over the years
 */
const expandEntry =
  (offset?: number) =>
  <T extends EntryType>(entry: T): Expanded<T> => ({
    ...entry,
    array: generateEntryArray(entry, offset),
  });

/**
 * Expands a transaction into an array from the starting point
 * The array is zero-padded from the starting point until year_from
 *
 * #TODO add inflation adjustment, interest etc
 * @param tx
 * @returns Ex: 4000 from year 3 to 5 = [ 0, 0, 0, 4000, 4000, 4000 ]
 */
export const generateEntryArray = (
  { recurring, year_from, year_to, value }: EntryType,
  offset = 0
): number[] => {
  if (!recurring || !year_to)
    return new Array(year_from + 1)
      .fill(0)
      .map((_, y) => (y >= offset && y === year_from ? value : 0));
  else
    return new Array(year_to + 1)
      .fill(0)
      .map((_, y) =>
        y >= offset && y >= year_from && y <= year_to ? value : 0
      );
};
