import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type {
  Age,
  ClientData,
  PartnerAge,
  PartnerYYYY,
  YYYY,
  YYYYMMDD,
} from 'src/clients/types';

/** Important: Assumes pastDate is always after date */
export const getYearsPassed = (
  pastDate: YYYYMMDD | dayjs.Dayjs,
  date: YYYYMMDD | dayjs.Dayjs = dayjs()
): number => dayjs(date).diff(dayjs(pastDate), 'years');

/** Can be negative */
export const getRelativeAgeAtDate = (
  { birthday }: ClientData,
  date: YYYYMMDD | dayjs.Dayjs = dayjs()
): Age => getYearOffset(date, birthday);

export const getInitialAge = (clientData: ClientData): Age =>
  getRelativeAgeAtDate(clientData, clientData.start_date);

/** Cannot be negative */
export const getPartnerInitialAge = ({
  start_date,
  partner,
}: ClientData): PartnerAge =>
  // max covers corner case where partner is much younger than main client
  partner && Math.max(0, getYearsPassed(partner?.birthday, start_date));

/** Can be negative */
export const getRelativePartnerInitialAge = ({
  start_date,
  partner,
}: ClientData): PartnerAge =>
  // max covers corner case where partner is much younger than main client
  partner && getYearOffset(start_date, partner?.birthday);

export const getInitialYear = ({ start_date }: ClientData): YYYY =>
  dayjs(start_date).year();

export const getBirthYear = ({ birthday }: ClientData): YYYY =>
  dayjs(birthday).year();

export const getPartnerBirthYear = ({ partner }: ClientData): PartnerYYYY =>
  partner && dayjs(partner?.birthday).year();

export const getYearAtAge = (client: ClientData, age: Age): YYYY =>
  getInitialYear(client) - getInitialAge(client) + age;

export const getYearAtPartnerAge = (
  client: ClientData,
  age: Age
): PartnerYYYY =>
  client.partner &&
  getInitialYear(client) - Number(getRelativePartnerInitialAge(client)) + age;

/**
 * Get the offset in years between two dates.
 *
 * Quirks:
 * * Negative value if `calc_date` < `base_date`, positive if `base_date` > `calc_date`
 * * -1 if `calc_date` is <= 1 year before `base_date`
 *
 * This can be used to calculate age differential between clients in some cases.
 *
 * Can also be used to calculate the inflation offset, i.e. number of years for which the inflation rate should be adjusted for. E.g.: if you make the exact retirement date
 *
 * * Retirement date on or within one year of the `base_date` should have no offset.
 * * Retirement 1 day before `base_date` should have offset -1 as it is assumed to use last year's rate.
 * * Retirement 1 year after `base_date` should have offset +1 as it is assumed to use next year's rate.
 *
 * See test cases for clarity. */
export const getYearOffset = (
  calc_date: YYYYMMDD | Dayjs,
  base_date: YYYYMMDD | Dayjs
): number => {
  const calcDate = dayjs(calc_date);
  const baseDate = dayjs(base_date);

  const isSameDayAndMonth = calcDate.isSame(
    baseDate.year(calcDate.year()),
    'day'
  );
  const isBefore = calcDate.isBefore(baseDate);

  const diffInYears = calcDate.diff(baseDate, 'years');

  return isBefore
    ? isSameDayAndMonth
      ? diffInYears
      : diffInYears - 1
    : diffInYears;
};
