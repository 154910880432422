import dayjs from 'dayjs';
import { getBirthYear } from 'src/calc/age/getInitialAge';
import type { ClientData } from 'src/clients/types';

export const makeYearArray = (
  clientData: ClientData,
  agesArray: number[]
): number[] => {
  const startDate = dayjs(clientData.start_date);
  const birthDate = dayjs(clientData.birthday);
  const birthYear = getBirthYear(clientData);

  /**
   guarantees the starting year is the same as the year from startDate
    
   usually we map age <=> year in which the client turns that age
   e.g. born in 2000 <=> age 20 in 2020
   
   however this adds problems when dealing with age bands
   
   if start_date is earlier in the year than the client's birthday,
   that means the initial year will be one less than the regular mapping would result
   e.g. born 01/02/2000, start_date 01/01/2020, age 19 <=> 2019
   
   therefore, to make sure start year is consistent with start date (e.g. 2020),
   we needto offset the mapping by increasing the years by 1
   */
  const offset = +startDate.isBefore(birthDate.year(startDate.year()));

  return agesArray.map((age) => birthYear + age + offset);
};
