import { getInitialAge } from 'src/calc/age/getInitialAge';
import { adjustEntryByInflationFn } from 'src/calc/transactions/adjustEntryByInflationFn';
import { combineEntries } from 'src/calc/transactions/combineEntries';
import { expandEntries } from 'src/calc/transactions/expandEntries';
import type {
  Transaction,
  TransactionExpanded,
} from 'src/clients/transactions/types';
import type { ClientData } from 'src/clients/types';

type FilterFunction = (transaction: Transaction) => boolean;

export type TransactionsDerived = {
  transactions: Transaction[];
  expanded: TransactionExpanded[];
  adjusted: TransactionExpanded[];
  combined: TransactionExpanded[];
};

/**
 * Retrieve transactions from client and process them
 *
 * @param filterFn optional
 *
 */
export const getTransactions = (
  clientData: ClientData,
  filterFn: FilterFunction = () => true
): TransactionsDerived => {
  const {
    transactions = [],
    inflation_rate,
    use_global_inflation,
    globals,
  } = clientData;

  const defaultInflation = 0.025;
  const inflation =
    (use_global_inflation ? globals?.inflation_rate : inflation_rate) ??
    defaultInflation;

  const filtered = transactions.filter(filterFn);

  const initialAge = getInitialAge(clientData);
  const expanded = expandEntries(filtered, initialAge) as TransactionExpanded[];

  const adjusted = expanded.map(
    adjustEntryByInflationFn(inflation, initialAge)
  ) as TransactionExpanded[];

  const combined = combineEntries(adjusted);

  return {
    transactions: filtered,
    expanded,
    adjusted,
    combined,
  };
};
