// This component needs to be used inside a react-admin form
import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import { get } from 'lodash';
import type { VFC } from 'react';
import type { InputProps } from 'react-admin';
import { NumberInput } from 'react-admin';
import { useFormState } from 'react-final-form';

const useStyles = makeStyles<Theme, { year?: number }>((theme) => ({
  input: {
    '& .MuiFilledInput-underline:after': {
      left: 46,
      bottom: 6,
      color: 'grey',
      content: ({ year }) => (year ? `"(${year.toString()})"` : ''),
      borderBottom: 'none',
      transform: 'none',
    },
  },
}));

export type AgeInputProps = InputProps & {
  dateSource: string;
};

export const AgeInput: VFC<AgeInputProps> = ({
  source,
  dateSource,
  className,
  ...props
}) => {
  const { values } = useFormState();
  //lodash is needed because simple obj[path] can't reach nested paths
  const age = get(values, source);
  const baseDate = get(values, dateSource);

  const year =
    age >= 0 && age !== NaN ? dayjs(baseDate).year() + age : undefined;

  const classes = useStyles({ year });

  return (
    <NumberInput
      {...{ source, ...props }}
      onWheel={({ target }) => (target as HTMLInputElement).blur()}
      className={`${className} ${classes.input}`}
    />
  );
};
