import { getAgeDiff } from 'src/calc/age/getClientAge';
import { makeAgesArray } from 'src/calc/age/makeAgesArray';
import type { TransactionTableData } from 'src/clients/tables/types';
import type { ClientData } from 'src/clients/types';

// returns an array of rows to formatted for DataGrid
// each row is an object containing entries { <column.id>: number }
export const makeAgeGridData = (maxLength: number) => {
  const agesArray = makeAgesArray(maxLength);
  const ageColumn: TransactionTableData = {
    id: 'age',
    title: 'Age',
    array: agesArray,
  };
  return { agesArray, ageColumn };
};

export const makePartnerAgeGridData = (
  clientData: ClientData,
  mainAgesArray: number[]
) => {
  const ageDiff = getAgeDiff(clientData);

  const agesArray = mainAgesArray.map((age) => age + ageDiff);
  // const agesArray = mainAgesArray;
  const ageColumn: TransactionTableData = {
    id: 'age',
    title: 'Age',
    array: agesArray,
  };
  return { agesArray, ageColumn };
};
