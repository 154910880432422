import { get } from 'lodash';
import type { FunctionFieldProps } from 'react-admin';
import type { ClientData } from 'src/clients/types';

export const renderPercentage =
  (
    minimumFractionDigits = 0,
    maximumFractionDigits = 1
  ): FunctionFieldProps<ClientData>['render'] =>
  (clientData, source) => {
    if (!clientData || !source) return '';

    return `${(get(clientData, source) * 100).toLocaleString('en-NZ', {
      minimumFractionDigits,
      maximumFractionDigits,
    })}%`;
  };

export const formatAsCurrency = (n: number) =>
  n.toLocaleString('en-NZ', { style: 'currency', currency: 'NZD' });
