import dayjs from 'dayjs';
import { makeValidateSync } from 'mui-rff';
import type { FC, VFC } from 'react';
import type { EditProps } from 'react-admin';
import {
  BooleanInput,
  DateInput,
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import * as Yup from 'yup';
import { Divider, FormGroup, Grid, Typography } from '@material-ui/core';
import { InflationForm } from 'src/clients/ClientEdit/InflationForm';
import { PartnerForm } from 'src/clients/ClientEdit/PartnerForm';
import { PortfolioSelect } from 'src/clients/ClientEdit/PortfolioForm';
import { useClientPageTitle } from 'src/clients/hooks/useClientPageTitle';
import { AgeInput } from 'src/components/forms/AgeInput';

export const ClientEdit: FC<EditProps> = (props) => {
  const title = `${useClientPageTitle(props.id)} - Edit`;

  return (
    <Edit {...{ ...props, title }}>
      <SimpleForm
        redirect="show"
        toolbar={<Toolbar alwaysEnableSaveButton={true} />}
        validate={validateClientForm}
      >
        <ClientForm />
      </SimpleForm>
    </Edit>
  );
};

export const ClientForm: VFC = () => (
  <>
    <FormGroup style={{ maxWidth: 900 }}>
      <Grid item md={3}>
        <DateInput source="start_date" />
      </Grid>
    </FormGroup>

    <Divider />

    <Typography>Main Client</Typography>
    <FormGroup style={{ maxWidth: 900 }}>
      <Grid container>
        <Grid item md={3}>
          <TextInput source="name" />
        </Grid>
        <Grid item md={3}>
          <DateInput source="birthday" />
        </Grid>
        <Grid item md={3}>
          <AgeInput source="retirement_age" dateSource="birthday" />
        </Grid>
      </Grid>
    </FormGroup>

    <Divider />

    <FormGroup style={{ maxWidth: 650 }}>
      <PartnerForm />
    </FormGroup>
    <Divider />
    <FormGroup style={{ maxWidth: 550 }}>
      <PortfolioSelect />
    </FormGroup>
    <Divider />
    <FormGroup style={{ maxWidth: 256 }}>
      <InflationForm />
    </FormGroup>
    <FormGroup style={{ maxWidth: 650 }}>
      <BooleanInput
        source="nz_super_auto"
        label="Automatically calculate NZ Super"
      />
    </FormGroup>
  </>
);

// a few issues pop up when trying to type the schema due to the nested partner field
// https://github.com/jquense/yup/issues/1302
// https://github.com/jquense/yup/issues/1247
// const schema: Yup.SchemaOf<Partial<Client>> = Yup.object({
const schema: Yup.AnySchema = Yup.object({
  id: Yup.string(),
  name: Yup.string().required(),
  birthday: Yup.string().required(),
  start_date: Yup.string()
    .required()
    .test(
      'startDate',
      "Can't be lower than birthday",
      (value, context) =>
        !!value && !dayjs(value).isBefore(dayjs(context.parent.birthday))
    ),
  retirement_age: Yup.number().min(0).required(),
  partner: Yup.object({
    name: Yup.string().required(),
    birthday: Yup.string().required(),
    retirement_age: Yup.number().min(0).required(),
  })
    .notRequired()
    .default(undefined),
  use_global_inflation: Yup.bool().required(),
  inflation_rate: Yup.number().required(),
  // such fields aren't part of the form so won't need to validate for now
  // portfolio: Yup.object(),
  // assets: Yup.array(),
  // transactions: Yup.array(),
  // incomeEntries: Yup.array(),
  // returns: Yup.array(),
  // snapshots: Yup.array(),
});

// leave it verbosey for debugging purposes
export const validateClientForm = (values: any) => {
  try {
    const val = makeValidateSync(schema)(values);
    return val;
  } catch (e) {
    console.error({ e });
  }
};
