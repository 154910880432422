import type { Asset } from 'src/clients/assets/types';
import type { Client } from 'src/clients/types';
import { defaultReturns, NZ_SUPER_ANNUAL_RATES } from 'src/globals/defaults';
import type { Globals, Portfolio, ReturnEstimates } from 'src/globals/types';

export const sampleEstimates: ReturnEstimates = {
  max_return: 0.136,
  avg_return: 0.037000000000000005,
  min_return: -0.08900000000000001,
};

export const samplePortfolio: Portfolio = {
  name: 'Custom',
  year1: sampleEstimates,
  year5: sampleEstimates,
  year10: sampleEstimates,
  year20: sampleEstimates,
};

export const sampleGlobals: Globals = {
  id: 'xRBzJU4CZNV4WMk1tcoj',
  kiwisaver: {
    govt_max_amount: 521.43,
    govt_rate: 0.5,
    rates: [
      { contribution: 0 },
      { contribution: 0.03 },
      { contribution: 0.04 },
      { contribution: 0.06 },
      { contribution: 0.08 },
      { contribution: 0.1 },
    ],
  },
  nz_super: { base_date: '2022-04-01', rates: NZ_SUPER_ANNUAL_RATES },
  portfolios: [
    {
      year5: {
        max_return: 0.055999999999999994,
        min_return: -0.003,
        avg_return: 0.032,
      },
      year1: { max_return: 0.128, avg_return: 0.032, min_return: -0.071 },
      year20: { min_return: 0.02, max_return: 0.038, avg_return: 0.032 },
      year10: {
        avg_return: 0.032,
        min_return: 0.01,
        max_return: 0.044000000000000004,
      },
      name: 'Conservative (30%)',
    },
    {
      year1: {
        min_return: -0.08900000000000001,
        max_return: 0.156,
        avg_return: 0.037000000000000005,
      },
      name: 'Conservative Balanced (30%)',
      year20: {
        max_return: 0.046,
        avg_return: 0.037000000000000005,
        min_return: 0.021,
      },
      year10: {
        min_return: 0.011000000000000001,
        avg_return: 0.037000000000000005,
        max_return: 0.054000000000000006,
      },
      year5: {
        avg_return: 0.037000000000000005,
        max_return: 0.071,
        min_return: -0.006999999999999999,
      },
    },
    {
      name: 'Balanced (30% Tax)',
      year20: { min_return: 0.021, max_return: 0.053, avg_return: 0.042 },
      year1: {
        max_return: 0.19399999999999998,
        avg_return: 0.042,
        min_return: -0.115,
      },
      year5: {
        avg_return: 0.042,
        max_return: 0.085,
        min_return: -0.013999999999999999,
      },
      year10: {
        max_return: 0.065,
        min_return: 0.006999999999999999,
        avg_return: 0.042,
      },
    },
    {
      year1: {
        min_return: -0.17600000000000002,
        avg_return: 0.042,
        max_return: 0.19399999999999998,
      },
      year5: {
        max_return: 0.11199999999999999,
        avg_return: 0.049,
        min_return: -0.027999999999999997,
      },
      name: 'Balanced Growth (30% Tax)',
      year10: { max_return: 0.079, min_return: 0.001, avg_return: 0.049 },
      year20: { min_return: 0.021, avg_return: 0.049, max_return: 0.065 },
    },
    {
      year10: {
        max_return: 0.10099999999999999,
        min_return: -0.01,
        avg_return: 0.057999999999999996,
      },
      year20: {
        min_return: 0.022000000000000002,
        max_return: 0.079,
        avg_return: 0.057999999999999996,
      },
      year5: {
        max_return: 0.134,
        min_return: -0.051,
        avg_return: 0.057999999999999996,
      },
      year1: {
        avg_return: 0.057999999999999996,
        max_return: 0.327,
        min_return: -0.23800000000000002,
      },
      name: 'Growth (30% Tax)',
    },
    {
      year5: {
        avg_return: 0.036000000000000004,
        max_return: 0.063,
        min_return: -0.002,
      },
      year10: {
        max_return: 0.049,
        min_return: 0.012,
        avg_return: 0.036000000000000004,
      },
      year20: {
        min_return: 0.023,
        avg_return: 0.036000000000000004,
        max_return: 0.042,
      },
      name: 'Conservative (17.5% tax)',
      year1: {
        avg_return: 0.036000000000000004,
        min_return: -0.071,
        max_return: 0.141,
      },
    },
    {
      year20: { avg_return: 0.042, min_return: 0.023, max_return: 0.051 },
      year5: {
        max_return: 0.078,
        avg_return: 0.040999999999999995,
        min_return: -0.006,
      },
      name: 'Conservative Balanced (17.5% tax)',
      year10: {
        min_return: 0.012,
        max_return: 0.06,
        avg_return: 0.040999999999999995,
      },
      year1: {
        avg_return: 0.040999999999999995,
        min_return: -0.08900000000000001,
        max_return: 0.17,
      },
    },
    {
      year20: { max_return: 0.069, avg_return: 0.047, min_return: 0.024 },
      name: 'Balanced (17.5% tax)',
      year1: { max_return: 0.213, avg_return: 0.047, min_return: -0.115 },
      year10: {
        max_return: 0.07200000000000001,
        min_return: 0.008,
        avg_return: 0.047,
      },
      year5: {
        max_return: 0.094,
        avg_return: 0.047,
        min_return: -0.013999999999999999,
      },
    },
    {
      year20: {
        min_return: 0.024,
        avg_return: 0.054000000000000006,
        max_return: 0.071,
      },
      year1: {
        min_return: -0.17600000000000002,
        avg_return: 0.054000000000000006,
        max_return: 0.271,
      },
      year10: {
        min_return: 0.002,
        avg_return: 0.054000000000000006,
        max_return: 0.087,
      },
      name: 'Balanced Growth (17.5% tax)',
      year5: {
        max_return: 0.12300000000000001,
        avg_return: 0.054000000000000006,
        min_return: -0.027999999999999997,
      },
    },
    {
      year20: { min_return: 0.024, max_return: 0.087, avg_return: 0.064 },
      name: 'Growth (17.5% tax)',
      year5: { min_return: -0.051, avg_return: 0.064, max_return: 0.146 },
      year1: {
        max_return: 0.35700000000000004,
        min_return: -0.23800000000000002,
        avg_return: 0.034,
      },
      year10: { min_return: -0.01, max_return: 0.111, avg_return: 0.064 },
    },
  ],
  inflation_rate: 0.04,
  income_tax_rates: [
    { rate: 0.105, ceiling: 14000 },
    { rate: 0.175, ceiling: 48000 },
    { ceiling: 70000, rate: 0.3 },
    { rate: 0.33, ceiling: 180000 },
    { ceiling: 999999999999999, rate: 0.39 },
  ],
};

export const sampleAssets: Asset[] = [
  {
    title: 'KiwiSaver',
    value: 5000,
    id: '2e4b962e-5c62-463f-ac76-d7896f3ed705',
  },
  {
    id: '221f6bcc-8ea1-41bf-bc6b-ec4f16e4779f',
    value: 47000,
    title: 'KiwiSaver',
  },
  {
    value: 580000,
    id: '90196263-708a-45a4-8530-cb70339178d6',
    title: 'ANZ MAC Investment',
  },
  {
    id: '8de9ad0d-6e8a-4e3f-946d-a164a9e9d9b6',
    title: 'Cash',
    value: 290000,
  },
  {
    id: '90713ec5-3c87-4452-aa2b-3739784601a0',
    value: 262000,
    title: 'Serious Saver',
  },
  {
    title: 'Proceeds from Property Sale',
    value: 290000,
    id: '3711fdb8-8ca2-4010-970b-b80572aa9f85',
  },
  {
    title: 'KM Family Trust Account',
    value: 18000,
    id: 'e40d2562-c4ab-4404-ba82-2e257ff9206e',
  },
  {
    id: '21cbdbcd-8e23-43c9-b34d-5d09fcbdf2a2',
    title: 'Double 5 Family Trust',
    value: 14000,
  },
  {
    value: 44000,
    id: '6f623dca-8e68-4975-8460-449fa8118181',
    title: 'Business Account ',
  },
];

export const sampleClient: Client = {
  id: 'g0cC8IpBoocEOQm4c6z5',
  portfolio: samplePortfolio,
  name: 'dave',
  globals: sampleGlobals,
  incomeEntries: [
    {
      kiwisaver: 0.03,
      id: 'a37c0944-d005-40d9-b049-1bb35feb742a',
      title: 'Salary',
      value: 57000,
      asset: 'Salary',
      type: 'Income',
      year_to: 4,
      inflation_adjusted: true,
      year_from: 0,
      recurring: true,
    },
    {
      year_to: 15,
      inflation_adjusted: false,
      id: 'bb629b85-1eb6-422e-80a3-d4465484abac',
      year_from: 5,
      kiwisaver: 0.04,
      type: 'Income',
      value: 70000,
      asset: 'Salary',
      recurring: true,
      title: 'Salary',
    },
  ],
  birthday: '1980-01-01',
  start_date: '2022-01-01',
  transactions: [
    {
      asset: 'Invest. Fund A',
      year_to: 8,
      title: 'Regular deposits',
      year_from: 0,
      inflation_adjusted: true,
      value: 30000,
      type: 'Contribution',
      recurring: true,
      id: 'b67b6729-96e9-4476-876c-97e191501bb0',
    },
    {
      recurring: true,
      type: 'Withdrawal',
      inflation_adjusted: true,
      title: 'Travel money',
      asset: 'Travel',
      id: 'fd5a947c-e25f-459c-94d7-198cc4d7a70f',
      value: 15000,
      year_to: 29,
      year_from: 19,
    },
    {
      inflation_adjusted: true,
      asset: 'Rental Raumati',
      value: 20000,
      year_from: 24,
      title: "Year's rent",
      type: 'Contribution',
      id: 'a5b54279-e353-4fdb-aac8-807e65208812',
      year_to: 34,
      recurring: true,
    },
    {
      inflation_adjusted: false,
      year_to: null,
      title: 'Downpayment',
      year_from: 9,
      type: 'Withdrawal',
      recurring: false,
      value: 600000,
      asset: 'Rental Raumati',
      id: 'bffa8382-3921-4b1e-9a75-2fc377e9b4ff',
    },
    {
      recurring: false,
      inflation_adjusted: false,
      value: 1000000,
      asset: 'Rental Raumati',
      title: 'Selling',
      type: 'Contribution',
      year_from: 34,
      id: '7c4e22ff-d4b9-4404-a112-9a93b6318d67',
    },
    {
      recurring: false,
      id: '2f0a994f-c357-4c44-b0eb-bd69c5be3c5d',
      inflation_adjusted: false,
      asset: 'Other',
      value: 50000,
      type: 'Withdrawal',
      year_from: 24,
      title: 'Savings compensation from birthday',
    },
    {
      type: 'Contribution',
      recurring: false,
      asset: 'KiwiSaver',
      id: '9d5148bb-6ab2-4ac6-aa1a-ab1f719f5b2d',
      value: 10000,
      inflation_adjusted: false,
      title: 'Kiwisaver voluntary contribution',
      year_from: 6,
    },
    {
      type: 'Contribution',
      recurring: true,
      title: 'Regular deposits',
      value: 50000,
      year_from: 9,
      id: 'a9603d00-b55a-4328-97d9-2b5129b5dedc',
      inflation_adjusted: true,
      year_to: 24,
      asset: 'Invest. Fund A',
    },
    {
      id: '4701a103-7488-4410-b4f8-85c6d27540eb',
      inflation_adjusted: false,
      title: 'Current Assets',
      asset: 'Other',
      recurring: false,
      year_from: 0,
      type: 'Contribution',
      value: 300000,
    },
    {
      type: 'Withdrawal',
      recurring: true,
      value: 5000,
      inflation_adjusted: true,
      id: 'b17fc740-440d-4575-9db4-82030ada6ddb',
      year_to: 34,
      title: 'Medical',
      year_from: 24,
      asset: 'Medical',
    },
    {
      id: '85549374-d8c4-41a7-a03e-9a73e915000e',
      title: 'Travel',
      value: 10000,
      asset: 'Travel',
      recurring: true,
      inflation_adjusted: true,
      type: 'Contribution',
      year_from: 30,
      year_to: 39,
    },
    {
      value: 15000,
      year_from: 35,
      asset: 'Medical',
      year_to: 49,
      type: 'Withdrawal',
      recurring: true,
      inflation_adjusted: true,
      title: 'Medical',
      id: 'cfb54f9c-968d-4922-bfdd-0fe11c307c78',
    },
    {
      year_from: 25,
      asset: 'Other',
      inflation_adjusted: true,
      year_to: 49,
      type: 'Withdrawal',
      value: 50000,
      recurring: true,
      id: 'c2e988c5-ecea-41b8-890c-5fb41999eea9',
      title: 'Regular Expenses',
    },
  ],
  initial_age: 50,
  retirement_age: 62,
  use_global_inflation: false,
  inflation_rate: 0.025,
  returns: defaultReturns,
  snapshots: [],
  assets: sampleAssets,
  nz_super_auto: true,
};
